import { Flag, Settings, AlertCircle } from 'react-feather'
import { Layout, Breadcrumb, message, Tour, Space, Button } from 'antd'
import type { TourProps } from 'antd'
import * as React from 'react'
import { H } from 'highlight.run'
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  UserButton,
  useAuth,
  useSession,
  useOrganizationList,
} from '@clerk/clerk-react'
import {
  BrowserRouter,
  NavLink,
  Navigate,
  Routes,
  useLocation,
  useNavigate,
  Route,
  Link,
  Outlet,
  useSearchParams,
} from 'react-router-dom'
import debounce from 'lodash/debounce'
import { useAppStore } from '../../stores/appStore'

import { Navigation } from '../../components/Navigation'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { PageBanner } from '../../components/PageBanner'
import { FullpageAppLoader } from '../../components/FullpageAppLoader'
import { UserAvatar } from '../../components/UserAvatar'
import { Logo } from '../../components/Logo'

const { Sider, Content, Header } = Layout

import {
  useGetMeQuery,
  useUpdateMeMutation,
  useChangeOrganizationModeMutation,
} from '../../graphql/_generated-hooks'
import { getPathnameByLevel } from '../../utils'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { CurrentPlanInfoBox } from '../../components/CurrentPlanInfoBox'
import { MaterialsView } from '../MaterialsView'
import { TemplatesView } from '../TemplatesView'
import { MaterialsDetailView } from '../MaterialsDetailView'
import { EstimatesFormView } from '../EstimatesFormView'
import { EstimatesOrderSheetsView } from '../EstimatesOrderSheetsView'
import { EstimatesDetailView } from '../EstimatesDetailView'
import { CustomerListView } from '../CustomerListView'
import { CustomerDetailView } from '../CustomerDetailView'
import { EstimateListView } from '../EstimateListView'
import { PreferenceView } from '../PreferenceView'
import { PreferenceUserProfileView } from '../PreferenceUserProfileView'
import { PreferenceOrganizationView } from '../PreferenceOrganizationView'
import { PreferenceEstimatesView } from '../PreferenceEstimatesView'
import { PreferenceBillingView } from '../PreferenceBillingView'
import { PreferenceIntegrationView } from '../PreferenceIntegrationView'
import { AppViewStyled } from './styles'
import { theme } from '../../styles/themes/default'

export interface IAppViewProps {}

export const AppView: React.FC<IAppViewProps> = React.memo(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isLoaded, userId, orgId, sessionId } = useAuth()
  const { session } = useSession()
  const [collapsed, setCollapsed] = React.useState(false)
  // const [showWelcome, setShowWelcome] = React.useState(false)
  const [updateMeMutation] = useUpdateMeMutation()
  const [
    changeOrganizationModeMutation,
    { loading: isChangingOrganizationMode },
  ] = useChangeOrganizationModeMutation()

  const searchObj = Object.fromEntries(searchParams.entries())
  const materialUsage = useAppStore((state: any) => state.materials)
  const estimateUsage = useAppStore((state: any) => state.estimates)
  const activePlan = useAppStore((state: any) => state.plan)
  const isExperimentalOrg = useAppStore((state: any) => state.isExperimental)
  const setMaterialsEstimatesPlan = useAppStore(
    (state: any) => state.setMaterialsEstimatesPlan
  )
  const setQuickbooksIntegrationConfig = useAppStore(
    (state: any) => state.setQuickbooksConfig
  )
  const setFeatureFlag = useAppStore((state: any) => state.setIsExperimental)

  const ref1 = React.useRef(null)
  const ref2 = React.useRef(null)

  // const steps: TourProps['steps'] = [
  //   {
  //     title: 'Welcome to VoltPro!',
  //     description: (
  //       <Space direction='vertical'>
  //         <p>
  //           The easiest way for sub-trades to track historical prices and manage
  //           a catalog of buildling materials/services.
  //         </p>
  //         <p style={{ marginTop: 0 }}>Lets take a quick tour of the app.</p>
  //       </Space>
  //     ),
  //     cover: (
  //       <img
  //         alt='welcome_onboarding_1.png'
  //         src='https://voltappstorage01.blob.core.windows.net/public-assets/app/welcome_onboarding_1.png'
  //       />
  //     ),
  //   },
  //   {
  //     title: 'Manage',
  //     description:
  //       'This is where you can manage your customers, organize materials into assemblies, and create estimates.',
  //     placement: 'right',
  //     // @ts-ignore
  //     target: () => ref1.current,
  //   },
  //   {
  //     title: 'Add items',
  //     description:
  //       'Create items manually by clicking this button. Items will populate your catalog and can be used to track prices for those items paid over time.',
  //     target: () => {
  //       // @ts-ignore
  //       return ref2?.current?.create
  //     },
  //   },
  //   {
  //     title: 'Upload Invoices/Receipts',
  //     description: (
  //       <Space direction='vertical'>
  //         <p>
  //           You can also add items automatically by uploading existing invoices
  //           from your vendor.
  //         </p>
  //         <p style={{ marginTop: 0 }}>
  //           Information is scanned from Invoices automatically through AI. All
  //           you need to do is verify the information and save!
  //         </p>
  //       </Space>
  //     ),
  //     // @ts-ignore
  //     target: () => ref2?.current?.upload,
  //   },
  // ]

  const showQueryParamMessage = debounce(
    (status: string, message: string | null) => {
      // @ts-ignore
      messageApi[status](message)
      searchParams.delete('status')
      searchParams.delete('message')
      setSearchParams(searchParams)
    },
    300
  )

  React.useEffect(() => {
    if (searchParams) {
      const status = searchParams.get('status')
      const messageContent = searchParams.get('message')

      if (status && messageApi.hasOwnProperty(status)) {
        showQueryParamMessage(status, messageContent)
      }
    }
  }, [searchParams])

  const {
    data: { me } = {},
    refetch,
    loading: loadingMe,
    updateQuery: updateMe,
  } = useGetMeQuery({
    onCompleted({ me }) {
      if (!me || !me.id) {
        return navigate('/sign-in')
      }

      if (me?.id && me?.email && process.env.NODE_ENV === 'production') {
        H.identify(me.email, {
          id: me.id,
          name: `${me.given_name} ${me.family_name}`,
          // @ts-ignore
          avatar: me.picture,
          // @ts-ignore
          organization: me?.organization?.id,
        })
      }

      if (me?.onboarding?.length) {
        const welcomeStep = me.onboarding.find(
          (item) => item.step === 'welcome'
        )
        if (!welcomeStep?.completed) {
          navigate('/estimates/create?onboarding=true')
          // setShowWelcome(true)
        }
      }

      if (me?.organization?.id) {
        setMaterialsEstimatesPlan(
          me?.organization?.materialCount || 0,
          me?.organization?.estimateCount || 0,
          me?.organization?.plan || null
        )

        setFeatureFlag(me?.organization?.experimental || false)
      }

      if (me?.organization?.integrationConfigs) {
        const quickbooksConfig = foundIntegrationConfig(
          me.organization.integrationConfigs,
          'quickbooks'
        )
        setQuickbooksIntegrationConfig(
          quickbooksConfig.enabled ? quickbooksConfig : null
        )
      }
    },
    onError(error) {
      // TODO handle unauthenticated error
      console.error(error)
    },
  })

  const foundIntegrationConfig = (
    integrationConfigs: any,
    provider: string
  ) => {
    return integrationConfigs.find(
      (config: any) => config.provider === provider
    )
  }

  // const handleOnTourClose = () => {
  //   setShowWelcome(false)
  //   updateMeMutation({
  //     variables: {
  //       input: {
  //         onboarding: [
  //           {
  //             step: 'welcome',
  //             completed: true,
  //           },
  //         ],
  //       },
  //     },
  //   })
  // }
  const handleOnSetOrganizationLivemode = () => {
    changeOrganizationModeMutation({
      variables: {
        input: {
          livemode: true,
        },
      },
      onCompleted() {
        message.success('Success! Your organization is now live.')
        handleNavigateAndRefresh()
      },
    })
  }
  const handleNavigateAndRefresh = () => {
    // Navigate to a specific route
    navigate('/materials')

    // Perform a hard refresh
    window.location.reload()
  }
  const needsQuickbooksReauth = (configs?: any) => {
    if (!configs) {
      return false
    }

    const quickbooksConfig = foundIntegrationConfig(configs, 'quickbooks')

    return quickbooksConfig?.needs_reauthentication
  }
  const handleQuickbooksReauth = () => {
    navigate('/settings/integrations')
  }
  const shouldHideDemoBanner = (pathname: string) => {
    const patterns = ['^/estimates/create$', '^/estimates/edit$']
    return patterns.some((pattern) => new RegExp(pattern).test(pathname))
  }

  return (
    <AppViewStyled>
      {contextHolder}
      {/* 
        Not rendering anything else until we load the user profile
        if no user exists in our DB and we render all child components
        multiple requests get fired and causes a race condition in the
        context method. Validation errors occur because multiple users
        with the same information are attempted to be created.
      */}
      {loadingMe && <FullpageAppLoader />}
      {!loadingMe && (
        <Layout>
          {/* <Header style={{ padding: 0, background: '#333' }} /> */}
          <Content>
            <Routes key={getPathnameByLevel(location.pathname, 1)}>
              <Route
                path='/sign-in/*'
                element={
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        left: '50%',
                        top: '25%',
                        marginLeft: '-225px',
                      }}
                    >
                      <SignIn routing='path' path='/sign-in' />
                    </div>
                  </>
                }
              />
              <Route
                path='/sign-up/*'
                element={<SignUp routing='path' path='/sign-up' />}
              />

              <Route
                element={
                  <>
                    <SignedIn>
                      <Sider
                        theme='light'
                        width={175}
                        className='layout-navigation hidden-print'
                        collapsible
                        collapsed={collapsed}
                        collapsedWidth={50}
                        trigger={null}
                        breakpoint='xl'
                        onBreakpoint={setCollapsed}
                      >
                        <Navigation
                          ref={ref1}
                          isFreePlan={!activePlan}
                          logo={<Logo size={collapsed ? 'sm' : 'lg'} />}
                          callToAction={
                            me?.organization?.livemode && (
                              <div
                                style={{
                                  marginBottom: collapsed ? '1.5em' : '3em',
                                }}
                              >
                                <CurrentPlanInfoBox
                                  popoverMode={collapsed}
                                  plan={activePlan}
                                  materialCount={materialUsage}
                                  materialTotal={
                                    me?.organization?.materialLimit || 0
                                  }
                                  estimateCount={estimateUsage}
                                  estimateTotal={
                                    me?.organization?.estimateLimit || 0
                                  }
                                  upgradeButton={
                                    <Button
                                      size='small'
                                      onClick={() =>
                                        navigate('/settings/membership')
                                      }
                                    >
                                      Upgrade
                                    </Button>
                                  }
                                />
                              </div>
                            )
                          }
                          settingsItem={
                            collapsed ? (
                              <Settings size={16} />
                            ) : (
                              <UserAvatar
                                picture={me?.picture || ''}
                                firstName={me?.given_name || me?.email || ''}
                                lastName={me?.family_name || ''}
                                secondaryText={<Space>Settings</Space>}
                                size={48}
                                maxWidth={130}
                              />
                            )
                          }
                        />
                      </Sider>
                      <Layout style={{ marginLeft: collapsed ? 50 : 175 }}>
                        <Outlet />
                      </Layout>
                    </SignedIn>
                  </>
                }
              >
                {/* 
                        Layout Route for the breadcrumb 
                        https://reactrouter.com/en/main/start/concepts#layout-routes 
                      */}
                <Route
                  element={
                    <>
                      {!me?.organization?.livemode &&
                        !shouldHideDemoBanner(location.pathname) && (
                          <PageBanner
                            icon={<Flag color={theme.colors.yellow} />}
                            message='Welcome to Demo Mode!'
                            description={`You're currently exploring VoltPro with demonstration data. Ready to start with your own data? Clear the demo data and go live.`}
                            type='warning'
                            action={
                              <Button
                                type='primary'
                                onClick={handleOnSetOrganizationLivemode}
                                loading={isChangingOrganizationMode}
                              >
                                Go Live & Clear Demo Data
                              </Button>
                            }
                          />
                        )}

                      {needsQuickbooksReauth(
                        me?.organization?.integrationConfigs
                      ) && (
                        <PageBanner
                          icon={<AlertCircle color={theme.colors.yellow} />}
                          message='Quickbooks Reauthorization'
                          description={`For security purposes, your Quickbooks integration needs to be reauthorized.`}
                          type='warning'
                          action={
                            <Button
                              type='primary'
                              onClick={handleQuickbooksReauth}
                            >
                              Reauthorize Quickbooks
                            </Button>
                          }
                        />
                      )}
                      <AnimatedComponent
                        customVariants={{
                          hidden: { opacity: 0, x: -20 },
                          visible: {
                            opacity: 1,
                            x: 0,
                            transition: { delay: 0.2 },
                          },
                        }}
                      >
                        <Breadcrumbs />
                      </AnimatedComponent>
                      <Outlet />
                    </>
                  }
                >
                  <Route
                    path='/estimates/create'
                    element={
                      <AnimatedComponent>
                        <EstimatesFormView
                          me={me}
                          navigationCollapsed={collapsed}
                        />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/estimates/:id/edit'
                    element={
                      <AnimatedComponent>
                        <EstimatesFormView
                          me={me}
                          navigationCollapsed={collapsed}
                        />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/estimates/:id/order-sheet'
                    element={
                      <AnimatedComponent>
                        <EstimatesOrderSheetsView
                          me={me}
                          navigationCollapsed={collapsed}
                        />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/estimates/:id'
                    element={
                      <AnimatedComponent>
                        <EstimatesDetailView me={me} />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/estimates'
                    element={
                      <AnimatedComponent>
                        <EstimateListView me={me} />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/customers'
                    element={
                      <AnimatedComponent>
                        <CustomerListView />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/customers/:id'
                    element={
                      <AnimatedComponent>
                        <CustomerDetailView />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/materials'
                    element={
                      <AnimatedComponent>
                        <MaterialsView me={me} ref={ref2} />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/materials/:id'
                    element={
                      <AnimatedComponent>
                        <MaterialsDetailView />
                      </AnimatedComponent>
                    }
                  />
                  <Route
                    path='/assemblies'
                    element={
                      <AnimatedComponent>
                        <TemplatesView />
                      </AnimatedComponent>
                    }
                  />
                </Route>
                <Route element={<PreferenceView />}>
                  <Route
                    index
                    path='/settings/profile'
                    element={<PreferenceUserProfileView />}
                  />
                  <Route
                    path='/settings/organization'
                    element={
                      <PreferenceOrganizationView
                        me={me}
                        redirectObj={searchObj}
                      />
                    }
                  />
                  <Route
                    path='/settings/estimates'
                    element={
                      <PreferenceEstimatesView
                        me={me}
                        redirectObj={searchObj}
                      />
                    }
                  />
                  <Route
                    path='/settings/membership'
                    element={
                      <PreferenceBillingView me={me} redirectObj={searchObj} />
                    }
                  />
                  {isExperimentalOrg && (
                    <Route
                      path='/settings/integrations'
                      element={<PreferenceIntegrationView me={me} />}
                    />
                  )}
                  <Route
                    path='/settings/*'
                    element={<Navigate to='/settings/profile' />}
                  />
                </Route>
                <Route path='*' element={<Navigate to='/materials' />} />
              </Route>
            </Routes>
          </Content>

          {/* <Tour
            open={showWelcome}
            onClose={handleOnTourClose}
            mask={true}
            steps={steps}
          /> */}
        </Layout>
      )}
      {/* <Analytics /> Needs to be in Apollo provider */}

      <SignedOut>
        {/* <RedirectToSignIn /> */}
        <Navigate to='/sign-in' />
      </SignedOut>
    </AppViewStyled>
  )
})

AppView.displayName = 'AppView'
