import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import * as React from 'react'

import { TotalSummaryRowStyled, RowLabelStyled, RowValueStyled } from './styles'

export interface TotalSummaryRowProps {
  label: string
  value: string
  bold?: string
  size?: number
  renderSuffix?: any
}

export const TotalSummaryRow = ({
  label,
  value,
  bold,
  size,
  renderSuffix,
  ...props
}: TotalSummaryRowProps) => {
  return (
    <TotalSummaryRowStyled>
      <RowLabelStyled bold={bold} size={size}>
        {label} {renderSuffix && renderSuffix}{' '}
      </RowLabelStyled>
      <RowValueStyled bold={bold} size={size}>
        {value}
      </RowValueStyled>
    </TotalSummaryRowStyled>
  )
}

TotalSummaryRow.displayName = 'TotalSummaryRow'
