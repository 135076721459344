import React from 'react'
import { BrowserRouter, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider, Empty } from 'antd'

import logo from './logo.svg'
import { GlobalStyles } from './styles/GlobalStyles'
import { theme } from './styles/themes/default'
import { AppView } from './views/AppView'
import { ClerkAuthProvider } from './clerk/ClerkAuthProvider'
import { AuthApolloProvider } from './graphql/AuthApolloProvider'
import { AnalyticsProvider } from './analytics/AnalyticsProvider'
import { MobileWarning } from './components/MobileWarning'
// import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import { ErrorBoundary } from '@highlight-run/react'

interface IAppProps {
  location?: string // this comes from SSR
}

export const App = (props: IAppProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <ErrorBoundary>
        <ConfigProvider
          locale={{
            locale: 'en-US',
          }}
          renderEmpty={() => <Empty description={false} />}
          theme={{
            token: {
              colorPrimary: theme.colors.primary,
              borderRadius: theme.borderRadius,
              colorTextBase: theme.colors.textPrimary,
              // colorSuccess: theme.colors.green,
              // colorWarning: theme.colors.yellow,
              colorLink: theme.colors.grey,
              colorBgLayout: theme.colors.background,
              colorInfoText: theme.colors.secondary, // Copy Button etc
              colorInfoHover: theme.colors.secondary,
              colorPrimaryActive: theme.colors.secondary, // Text click active color
              boxShadow: theme.boxShadow,
              boxShadowSecondary: theme.boxShadow,
            },
            components: {
              Menu: {
                activeBarBorderWidth: 0,
                groupTitleFontSize: 24,
                itemSelectedBg: '#ffffff',
                itemSelectedColor: theme.colors.accent,
                itemMarginInline: 0,
              },
              Collapse: {
                headerPadding: '12px 0px',
                contentPadding: '16px 0px',
              },
            },
          }}
        >
          <BrowserRouter>
            <AnalyticsProvider>
              <ClerkAuthProvider>
                <AuthApolloProvider>
                  <MobileWarning />
                  <AppView />
                </AuthApolloProvider>
              </ClerkAuthProvider>
            </AnalyticsProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
