import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Space } from 'antd'
import * as React from 'react'

import { VoltProIcon } from '../VoltProIcon'

import { MaterialSelectItemRenderStyled } from './styles'
export interface MaterialSelectItemRenderProps {
  title: string
  subtitle?: string
  globalCatalog?: boolean
}

export const MaterialSelectItemRender = ({
  title = 'N/A',
  subtitle,
  globalCatalog,
}: MaterialSelectItemRenderProps) => {
  return (
    <MaterialSelectItemRenderStyled>
      <Space>
        {globalCatalog && (
          <span style={{ opacity: '0.35' }}>
            <VoltProIcon tooltipTitle='VoltPro global price catalog' />
          </span>
        )}
        <Typography.Text>{title}</Typography.Text>
      </Space>
      {subtitle && (
        <>
          {' '}
          - <Typography.Text type='secondary'>{subtitle}</Typography.Text>
        </>
      )}
    </MaterialSelectItemRenderStyled>
  )
}

MaterialSelectItemRender.displayName = 'MaterialSelectItemRender'
