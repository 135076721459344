import {
  CloseOutlined,
  EditOutlined,
  RollbackOutlined,
} from '@ant-design/icons'
import {
  Descriptions,
  Button,
  Tooltip,
  Card,
  Progress,
  Space,
  Badge,
  Popover,
} from 'antd'
import styled from 'styled-components'
import * as React from 'react'

import { TaxTotalSummaryStyled } from './styles'
import { theme } from '../../styles/themes/default'
import { formatMoney } from '../../utils'
import { Tool } from 'react-feather'
import { TotalSummaryProfitBreakdown } from './TotalSummaryProfitBreakdown'
import { TotalSummaryRow } from './TotalSummaryRow'
import { on } from 'events'

export interface TaxTotalSummaryProps {
  profit?: number | null
  total?: number | null
  subtotal?: number | null
  materials?: number | null
  cost?: number | null
  labor?: number | null
  materialProfit?: number | null
  laborProfit?: number | null
  discount?: number | null
  taxes?: any
  currency?: string | null
  showBreakdown?: boolean
  allowTaxRemove?: boolean
  allowDiscountRemove?: boolean
  allowEditSubtotal?: boolean
  allowClearSubtotal?: boolean
  onEditSubtotal?: () => void
  onClearSubtotal?: () => void
  onTaxRemove?: (tax: any) => void
  onDiscountRemove?: () => void
}

export const TaxTotalSummary = ({
  profit,
  total = 0,
  subtotal = 0,
  materials = 0,
  cost = 0,
  labor = 0,
  materialProfit = 0,
  laborProfit = 0,
  discount = 0,
  taxes = [],
  currency,
  showBreakdown = false,
  allowTaxRemove,
  allowDiscountRemove,
  allowEditSubtotal,
  allowClearSubtotal,
  onEditSubtotal = () => {},
  onClearSubtotal = () => {},
  onTaxRemove = () => {},
  onDiscountRemove = () => {},
}: TaxTotalSummaryProps) => {
  const calculateTaxAmount = (tax: any) => {
    const { percentage, amount } = tax || {}
    if (percentage && amount) {
      return amount
    } else if (percentage && subtotal) {
      return (subtotal * percentage) / 100
    } else {
      return 0
    }
  }

  return (
    <TaxTotalSummaryStyled>
      {showBreakdown && (
        <TotalSummaryProfitBreakdown
          materialProfit={materialProfit}
          laborProfit={laborProfit}
          disableProfitBreakdown={allowClearSubtotal}
          profit={profit}
          materials={materials}
          labor={labor}
          cost={cost}
          subtotal={subtotal}
          currency={currency}
        />
      )}
      <div>
        <span data-testid='tax-total-summary-subtotal'>
          <TotalSummaryRow
            label='Subtotal'
            value={formatMoney(subtotal)}
            renderSuffix={
              <>
                {allowClearSubtotal && (
                  <Button
                    onClick={() => onClearSubtotal()}
                    type='text'
                    size='small'
                    icon={
                      <Tooltip title='Reset'>
                        <RollbackOutlined />
                      </Tooltip>
                    }
                  />
                )}
                {allowEditSubtotal && (
                  <Button
                    type='text'
                    size='small'
                    onClick={onEditSubtotal}
                    icon={
                      <Tooltip title='Edit'>
                        <EditOutlined />
                      </Tooltip>
                    }
                  />
                )}
              </>
            }
          />
        </span>

        {!!discount && (
          <span data-testid='tax-total-summary-discount'>
            <TotalSummaryRow
              label={`Discount (${discount}%)`}
              value={`-${formatMoney(((subtotal || 0) * discount) / 100)}`}
              renderSuffix={
                !!allowDiscountRemove && (
                  <Button
                    onClick={() => onDiscountRemove()}
                    type='text'
                    size='small'
                    icon={
                      <Tooltip title='Remove'>
                        <CloseOutlined />
                      </Tooltip>
                    }
                    danger
                  />
                )
              }
            />
          </span>
        )}

        {taxes &&
          taxes?.map((tax: any, $index: number) => {
            return (
              <TotalSummaryRow
                key={$index}
                label={`${tax.display_name || 'Misc.'} (${tax.percentage}%)`}
                renderSuffix={
                  !!allowTaxRemove && (
                    <Button
                      onClick={() => onTaxRemove(tax)}
                      type='text'
                      size='small'
                      icon={
                        <Tooltip title='Remove'>
                          <CloseOutlined />
                        </Tooltip>
                      }
                      danger
                    />
                  )
                }
                value={formatMoney(calculateTaxAmount(tax))}
                data-testid='tax-total-summary-tax'
              />
            )
          })}

        <TotalSummaryRow
          label='Total'
          value={`${formatMoney(total)} ${currency && currency}`}
          size={18}
          bold='true'
          data-testid='tax-total-summary-total'
        />
      </div>
    </TaxTotalSummaryStyled>
  )
}

TaxTotalSummary.displayName = 'TaxTotalSummary'
