import {
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  message,
  Form,
  Collapse,
  Space,
  Divider,
  Row,
  Col,
  Select,
  Typography,
  Checkbox,
  Tooltip,
  InputNumber,
  Switch,
} from 'antd'
import {
  DrawerForm,
  ProForm,
  ProFormText,
  ProFormTextArea,
  ProFormMoney,
  ProFormDatePicker,
  ProFormDigit,
} from '@ant-design/pro-components'
import * as React from 'react'
import debounce from 'lodash/debounce'

import { theme } from '../../styles/themes/default'
import { MaterialFormDrawerStyled } from './styles'
import {
  isObjectEmpty,
  capitalizeString,
  roundNumber,
  roundMoney,
  setApiFormErrors,
} from '../../utils'
import { useAppStore } from '../../stores/appStore'
import {
  useCreateProductMutation,
  useUpdateProductMutation,
  ProductTypeFieldInput,
  useValidateProductSkuLazyQuery,
} from '../../graphql/_generated-hooks'

import { DistributorSearchSelect } from '../DistributorSearchSelect'
import { CategorySelect } from '../CategorySelect'

const DRAWER_WIDTH = 450

export interface MaterialFormDrawerProps {
  onClose?: () => void
  onSave?: (data: any) => void
  onCreateNewDistributor?: () => void
  onCreateNewCategory?: () => void
  newDistributor?: any
  newCategory?: any
  open?: boolean
  mode?: 'create' | 'edit'
  isDemo?: boolean
  allowServiceCreate?: boolean
  material?: any
}

export interface MaterialServiceFormSchema {
  id: string
  name: string
  description: string
  unit_amount: number
  unit_type: string
  fixed_price: number
  invoicedAt: string
  categories: any[]
  sku: string
  distributor: {
    value: string
    label: string
  }
}

export const MaterialFormDrawer = ({
  onClose = () => {},
  onSave = (data: any) => {},
  onCreateNewDistributor = () => {},
  onCreateNewCategory = () => {},
  newDistributor,
  newCategory,
  open,
  mode = 'create',
  isDemo = false,
  allowServiceCreate = true,
  material,
}: MaterialFormDrawerProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()

  const [isLaborItem, setIsLaborItem] = React.useState(
    material?.type === ProductTypeFieldInput.Service
  )
  const [isCoverageItem, setIsCoverageItem] = React.useState(
    material?.coverage_item || false
  )
  const [validatingProductSku, setValidatingProductSku] = React.useState(false)

  const [createProduct, { data, loading: createProductLoading, error }] =
    useCreateProductMutation()
  const [updateProduct, { loading: updateProductLoading }] =
    useUpdateProductMutation()
  const [validateProductSku] = useValidateProductSkuLazyQuery()

  // State
  const addMaterial = useAppStore((state: any) => state.addMaterial)

  // Refs
  const materialHandledRef = React.useRef(false)
  const lastCategoryRef = React.useRef(null)

  React.useEffect(() => {
    if (newDistributor && newDistributor.id) {
      form.setFieldValue('distributor', {
        value: newDistributor.id,
        label: newDistributor.name,
      })
    }

    if (
      newCategory &&
      newCategory.id &&
      lastCategoryRef.current !== newCategory.id
    ) {
      const currentCategories = form.getFieldValue('categories') || []
      form.setFieldValue('categories', [
        ...currentCategories,
        ...[
          {
            value: newCategory.id,
            label: newCategory.name,
          },
        ],
      ])

      lastCategoryRef.current = newCategory.id
    }

    if (
      material &&
      !isObjectEmpty(material) &&
      open &&
      !materialHandledRef.current
    ) {
      // TODO refactor to merge with default values
      if (!material?.categories) {
        material.categories = []
      }
      form.setFieldsValue({
        ...material,
        categories: [
          ...material?.categories?.map((c: any) => ({
            value: c.id,
            label: c.name,
          })),
        ],
      })

      materialHandledRef.current = true
    }

    if (material?.coverage_item) {
      setIsCoverageItem(material?.coverage_item)
    } else {
      setIsCoverageItem(false)
    }

    if (material?.type === ProductTypeFieldInput.Service) {
      setIsLaborItem(true)
    }

    // Reset the ref when drawer closes
    if (!open) {
      materialHandledRef.current = false
    }
  }, [newDistributor, newCategory, material, open])

  const validateSkuField = React.useCallback(
    debounce(async (sku: any) => {
      if (sku) {
        setValidatingProductSku(true)
        const { data } = await validateProductSku({
          fetchPolicy: 'no-cache',
          variables: {
            input: { sku },
          },
          onCompleted(data) {
            setValidatingProductSku(false)
          },
        })

        if (
          data?.validateProductSku &&
          !data?.validateProductSku?.valid &&
          data?.validateProductSku?.data
        ) {
          console.log('set validation error', data.validateProductSku.data)
        }
      }
    }, 1500),
    []
  )

  const handleCreateProduct = async (values: MaterialServiceFormSchema) => {
    const {
      description,
      unit_amount,
      fixed_price,
      distributor,
      sku,
      categories,
      ...rest
    } = values

    createProduct({
      variables: {
        input: {
          unit_amount: roundMoney(roundNumber(unit_amount) * 100),
          type: isLaborItem
            ? ProductTypeFieldInput.Service
            : ProductTypeFieldInput.Good,
          ...(fixed_price && {
            fixed_price: roundMoney(roundNumber(fixed_price) * 100),
          }),
          ...(description && { description }),
          ...(distributor?.value && { distributorId: distributor.value }),
          ...(sku && { sku }),
          ...(isDemo && { metadata: { demo: true } }),
          ...(categories && { categories: categories.map((c) => c.value) }),
          ...rest,
        },
      },
      onCompleted({ createProduct }: any = {}) {
        // Update store
        addMaterial()

        messageApi.success('Success: Material created successfully!')

        onSave(createProduct.data)
      },
      onError(error, clientOptions) {
        console.log('error', error)
        setApiFormErrors(error.graphQLErrors, form)
        messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      },
    })
  }
  const handleEditProduct = async (values: MaterialServiceFormSchema) => {
    const { unit_amount, fixed_price, categories, ...rest } = values
    updateProduct({
      variables: {
        id: material.id,
        input: {
          ...(unit_amount && {
            unit_amount: roundMoney(roundNumber(unit_amount) * 100),
          }),
          ...(fixed_price && {
            fixed_price: roundMoney(roundNumber(fixed_price) * 100),
          }),
          ...(categories && { categories: categories.map((c) => c.value) }),
          ...rest,
        },
      },
      onCompleted({ updateProduct }: any = {}) {
        messageApi.success('Success: Material updated successfully!')

        onSave(updateProduct.data)
      },
      onError(error, clientOptions) {
        setApiFormErrors(error.graphQLErrors, form)
        messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      },
    })
  }
  const handleOnFinish = async (values: MaterialServiceFormSchema) => {
    if (mode === 'create') {
      handleCreateProduct(values)
    } else if (mode === 'edit' && material.id) {
      handleEditProduct(values)
    }
  }
  const handleLaborItemChange = (e: any) => {
    setIsLaborItem(e.target.checked)
  }

  return (
    <MaterialFormDrawerStyled>
      {contextHolder}
      <DrawerForm<MaterialServiceFormSchema>
        id='material-form'
        title={`${capitalizeString(mode)} ${
          isLaborItem ? 'Service' : 'Material'
        }`}
        open={open}
        form={form}
        autoFocusFirstInput
        drawerProps={{
          getContainer: false, // To supress form render warning(https://stackoverflow.com/questions/61056421/warning-instance-created-by-useform-is-not-connect-to-any-form-element)
          destroyOnClose: true,
          maskClosable: false,
          onClose: () => {
            onClose()
          },
          width: DRAWER_WIDTH,
          extra: (
            <>
              {allowServiceCreate && (
                <Checkbox
                  checked={isLaborItem}
                  onChange={handleLaborItemChange}
                  disabled={mode === 'edit'}
                  data-testid='material-form-service-checkbox'
                >
                  Service
                </Checkbox>
              )}
            </>
          ),
        }}
        submitter={{
          searchConfig: {
            submitText: mode === 'create' ? 'Create' : 'Update',
            resetText: 'Cancel',
          },
          submitButtonProps: {
            loading: createProductLoading || updateProductLoading,
            id: 'material-form-submit-button',
          },
          resetButtonProps: {
            id: 'material-form-cancel-button',
          },
        }}
        onFinish={handleOnFinish}
      >
        <ProForm.Group>
          <ProFormText
            fieldProps={{
              'data-1p-ignore': '',
            }}
            name='name'
            width='xl'
            rules={[
              {
                required: true,
                message: 'Please enter an material name',
              },
            ]}
            label='Name'
            placeholder={
              isLaborItem
                ? 'Service Call Hourly'
                : 'XXCABLE 6-4C TECK Conductors Black'
            }
          />
          <ProFormText
            name='description'
            width='xl'
            rules={[
              {
                required: false,
                message: 'Please enter a description',
              },
            ]}
            label={
              <Space
                direction='horizontal'
                size={'small'}
                split={<Divider type='vertical' />}
              >
                Description
              </Space>
            }
            placeholder={
              isLaborItem ? 'After hours rate' : '6-4 Copper Round Cable'
            }
            data-testid='material-form-description'
            id='material-form-description'
          />
        </ProForm.Group>
        {!isLaborItem && (
          <ProForm.Group>
            <ProFormText
              name='sku'
              width='xl'
              label={
                <Space>
                  Item Number / SKU
                  {validatingProductSku && <LoadingOutlined />}
                </Space>
              }
              placeholder='xxxx-xxxx-xxxx'
            />
          </ProForm.Group>
        )}
        {mode === 'create' && !isLaborItem && (
          <>
            <ProForm.Group>
              <ProFormDatePicker
                name='invoicedAt'
                label='Invoice Date'
                placeholder='00/00/0000'
                width='xl'
              />
            </ProForm.Group>
            <Row gutter={16}>
              <Col span={12}>
                <ProForm.Group>
                  <ProFormMoney
                    name='unit_amount'
                    width='xl'
                    label='Unit Cost'
                    placeholder='0.00'
                  />
                </ProForm.Group>
              </Col>

              <Col span={12}>
                <Form.Item label='Unit Type' name='unit_type'>
                  <Select
                    style={{ width: '500px', maxWidth: '100%' }}
                    options={[
                      { value: 'E', label: 'E' },
                      { value: 'C', label: 'C' },
                      { value: 'M', label: 'M' },
                      { value: 'ea', label: 'ea' },
                      { value: 'ft', label: 'ft' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {isLaborItem && (
          <Row gutter={16}>
            <Col span={24}>
              <ProForm.Group>
                <ProFormMoney
                  name='unit_amount'
                  width='xl'
                  label='Labor Cost'
                  placeholder='0.00'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a labor cost',
                    },
                  ]}
                  fieldProps={{
                    suffix: (
                      <Typography.Paragraph
                        type='secondary'
                        style={{ marginBottom: 0 }}
                      >
                        per hour
                      </Typography.Paragraph>
                    ),
                  }}
                />
              </ProForm.Group>
            </Col>
            <Col span={24}>
              <ProForm.Group>
                <ProFormMoney
                  name='fixed_price'
                  width='xl'
                  label='Labor Rate'
                  placeholder='0.00'
                  rules={[
                    {
                      required: false,
                      message: 'Please enter a labor rate',
                    },
                  ]}
                  fieldProps={{
                    suffix: (
                      <Typography.Paragraph
                        type='secondary'
                        style={{ marginBottom: 0 }}
                      >
                        per hour
                      </Typography.Paragraph>
                    ),
                  }}
                />
              </ProForm.Group>
            </Col>
          </Row>
        )}
        {mode === 'edit' && !isLaborItem && (
          <Form.Item label='Unit Type' name='unit_type'>
            <Select
              style={{ width: '500px', maxWidth: '100%' }}
              options={[
                { value: 'E', label: 'E' },
                { value: 'C', label: 'C' },
                { value: 'M', label: 'M' },
                { value: 'ea', label: 'ea' },
                { value: 'ft', label: 'ft' },
              ]}
            />
          </Form.Item>
        )}
        <Form.Item label='Categories' name='categories'>
          <CategorySelect
            onAddClick={onCreateNewCategory}
            createButtonVisible={true}
            createButtonText='New category'
            recentlyCreatedCategory={
              newCategory
                ? [{ value: newCategory.id, label: newCategory.name }]
                : []
            }
          />
        </Form.Item>
        <ProForm.Group>
          <ProFormTextArea
            width='xl'
            name='notes'
            label='Notes'
            placeholder='Additional notes'
          />
        </ProForm.Group>
        {mode === 'create' && !isLaborItem && (
          <DistributorSearchSelect
            formItemProps={{
              label: 'Vendor',
              name: 'distributor',
              rules: [
                {
                  required: true,
                },
              ],
            }}
            onAddClick={onCreateNewDistributor}
            recentlyCreatedDistributor={
              newDistributor
                ? [{ value: newDistributor.id, label: newDistributor.name }]
                : []
            }
          />
        )}
        {/* Advanced Options */}
        <Divider />
        <Collapse
          style={{ padding: '1em 0' }}
          defaultActiveKey={
            material?.coverage_item || material?.coverage_rate ? ['1'] : []
          }
          expandIconPosition='start'
          collapsible='header'
          expandIcon={({ isActive }) =>
            isActive ? <MinusOutlined /> : <PlusOutlined />
          }
          ghost
          items={[
            {
              key: '1',
              label: 'Advanced options',
              children: (
                <>
                  {!isLaborItem && (
                    <>
                      <Form.Item
                        layout='horizontal'
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        label={
                          <Space>
                            <Typography.Text>
                              Coverage-Based Material
                            </Typography.Text>
                            <Tooltip title='Materials that are meant to be applied over an area and their usage is calculated based on surface area coverage.'>
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Space>
                        }
                        name={'coverage_item'}
                      >
                        <Switch
                          value={false}
                          onChange={(value) => {
                            setIsCoverageItem(value)
                          }}
                        />
                      </Form.Item>
                      {isCoverageItem && (
                        // style={{ paddingLeft: '20px' }}
                        <div>
                          <Form.Item
                            layout='horizontal'
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            // help='How much area will a single unit of this item yield in a job.'
                            label={
                              <Space>
                                <Typography.Text>Coverage Rate</Typography.Text>
                                <Tooltip title='How much area will a single unit of this item yield in a job.'>
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </Space>
                            }
                            name={'coverage_rate'}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a coverage rate',
                              },
                            ]}
                          >
                            <InputNumber
                              precision={0}
                              min={1}
                              suffix='sqft'
                              placeholder={'100'}
                              style={{
                                width: '100%',
                                minWidth: '75px',
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            layout='horizontal'
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            label={
                              <Space>
                                <Typography.Text>Single-Use</Typography.Text>
                                <Tooltip title='Single-use items will lose any unused portion during a job. For example, adhesives and sealants which cannot be stored or reused after opening.'>
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </Space>
                            }
                            name={'single_use'}
                            valuePropName='checked'
                          >
                            <Checkbox defaultChecked={false} />
                          </Form.Item>
                        </div>
                      )}
                    </>
                  )}
                  {isLaborItem && (
                    <Form.Item
                      layout='horizontal'
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                      // help='How much area will a single unit of this item yield in a job.'
                      label={
                        <Space>
                          <Typography.Text>Service area</Typography.Text>
                          <Tooltip title='How much area can be covered per hour in a job.'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Space>
                      }
                      name={'coverage_rate'}
                      rules={[
                        {
                          required: false,
                          message: 'Please enter a service area',
                        },
                      ]}
                    >
                      <InputNumber
                        precision={0}
                        min={1}
                        suffix='sqft'
                        placeholder={'100'}
                        style={{
                          width: '100%',
                          minWidth: '75px',
                        }}
                      />
                    </Form.Item>
                  )}
                </>
              ),
            },
          ]}
        />
      </DrawerForm>
    </MaterialFormDrawerStyled>
  )
}

MaterialFormDrawer.displayName = 'MaterialFormDrawer'
