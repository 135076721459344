import * as React from 'react'
import styled, { keyframes } from 'styled-components'

// Import your images
const defaultImage = `${process.env.PUBLIC_URL}/assets/integrations/quickbooks/C2QB_transparent_med.png`
const hoverImage = `${process.env.PUBLIC_URL}/assets/integrations/quickbooks/C2QB_transparent_med_hover.png`

const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const QuickbooksIntegrationButtonStyled = styled.div`
  position: relative;
  width: 223px; // Adjust as needed
  height: 36px; // Adjust as needed
  border: none;
  background: none;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
`

export const QuickbooksIntegrationBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${defaultImage});
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease-in-out;
`

export const QuickbooksIntegrationHoverImage = styled(
  QuickbooksIntegrationBackgroundImage
)`
  opacity: 0.85;
  background-image: url(${hoverImage});
  opacity: 0;
  animation: ${fadeAnimation} 0.3s ease-in-out;
`
