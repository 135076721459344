import {
  Remirror,
  EditorComponent,
  useRemirror,
  useHelpers,
  useDocChanged,
  useRemirrorContext,
} from '@remirror/react'
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  StrikeExtension,
  OrderedListExtension,
  BulletListExtension,
  PlaceholderExtension,
} from 'remirror/extensions'
import { CountExtension } from '@remirror/extension-count'
import * as React from 'react'

import { RichTextEditorStyled } from './styles'
import { RichTextEditorMenu } from './RichTextEditorMenu'

export interface RichTextEditorProps {
  initialContent?: any
  onChange?: (value: any) => void
  onChangeHtml?: (value: any) => void
}

export interface EditorRef {
  setContent: (content: any) => void
}

const INPUT_MAXIMUM = 10000

const ImperativeHandle = React.forwardRef(
  (_: unknown, ref: React.Ref<EditorRef>) => {
    const { setContent } = useRemirrorContext({
      autoUpdate: true,
    })

    React.useImperativeHandle(ref, () => ({ setContent }))

    return null
  }
)
ImperativeHandle.displayName = 'ImperativeHandle'

export const RichTextEditor = ({
  initialContent = '',
  onChange = () => {},
  onChangeHtml = () => {},
  ...props
}: RichTextEditorProps) => {
  const editorRef = React.useRef<EditorRef | null>(null)
  const [isSticky, setIsSticky] = React.useState(false)
  const toolbarRef = React.useRef<HTMLDivElement>(null)

  const { manager, state } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new StrikeExtension(),
      new OrderedListExtension(),
      new BulletListExtension(),
      new PlaceholderExtension({ placeholder: 'Enter work scope information' }),
      new CountExtension({
        maximum: INPUT_MAXIMUM,
      }),
    ],
    content: initialContent,
    selection: 'start',
    stringHandler: 'html',
  })

  React.useEffect(() => {
    if (initialContent) {
      editorRef.current!.setContent(initialContent)
    }
  }, [initialContent])

  React.useEffect(() => {
    const handleScroll = () => {
      if (toolbarRef.current) {
        const { top } = toolbarRef.current.getBoundingClientRect()
        setIsSticky(top <= 0)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const hooks = [
    () => {
      const { getJSON, getHTML } = useHelpers()
      const handleSaveShortcut = React.useCallback(
        ({ state }: any) => {
          const stateObj = { ...getJSON(state), html: getHTML(state) }
          onChange(stateObj)
          return true
        },
        [getJSON, getHTML]
      )

      useDocChanged(handleSaveShortcut)
    },
  ]

  return (
    <RichTextEditorStyled>
      <Remirror manager={manager} initialContent={state} hooks={hooks}>
        <div
          ref={toolbarRef}
          className={`toolbar ${isSticky ? 'sticky-toolbar' : ''}`}
        >
          <RichTextEditorMenu />
        </div>
        <div className='editor-wrapper'>
          <EditorComponent />
        </div>
        <ImperativeHandle ref={editorRef} />
      </Remirror>
    </RichTextEditorStyled>
  )
}

RichTextEditor.displayName = 'RichTextEditor'
