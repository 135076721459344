import { Tag, Badge } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import {
  QuickbooksIntegrationButtonStyled,
  QuickbooksIntegrationBackgroundImage,
  QuickbooksIntegrationHoverImage,
} from './styles'

export interface QuickbooksIntegrationButtonProps {
  onClick?: () => void
}

export const QuickbooksIntegrationButton = ({
  onClick,
}: QuickbooksIntegrationButtonProps) => {
  return (
    <QuickbooksIntegrationButtonStyled onClick={onClick}>
      <QuickbooksIntegrationBackgroundImage />
      <QuickbooksIntegrationHoverImage className='hover-image' />
    </QuickbooksIntegrationButtonStyled>
  )
}

QuickbooksIntegrationButton.displayName = 'QuickbooksIntegrationButton'
