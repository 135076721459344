import type { ActionType, ProColumns } from '@ant-design/pro-components'
import { ProTable } from '@ant-design/pro-components'
import {
  Progress,
  Space,
  Tooltip,
  Tag,
  Table,
  Typography,
  Row,
  Col,
  Avatar,
} from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useGetCustomersQuery,
  CustomerDetailFragment,
} from '../../graphql/_generated-hooks'
import { formatMoney, formatDate } from '../../utils'
import { DemoItemTag } from '../DemoItemTag'
import { QuickbooksIcon } from '../QuickbooksIcon'
import { CustomersTableStyled } from './styles'

export interface CustomersTableProps {
  data?: any // TODO: CustomerDetailFragment[]
  tableProps: TableProps<CustomerDetailFragment>
  selectedId?: string
  onCustomerClick?: (user: CustomerDetailFragment, event: any) => void
  onCustomerDelete?: (user: CustomerDetailFragment) => void
  onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void
}

const { Title, Paragraph } = Typography

const columns: ColumnsType<CustomerDetailFragment> = [
  // {
  //   dataIndex: 'index',
  //   valueType: 'indexBorder',
  //   width: 48,
  // },
  // {
  //   title: 'Description',
  //   key: 'description',
  //   dataIndex: 'description',
  //   sorter: true,
  //   render: (_, { description = '' }) => (
  //     <Row align='middle' gutter={16}>
  //       <Col>
  //         <Avatar size={36}>{description?.[0]}</Avatar>
  //       </Col>
  //       <Col>
  //         <Typography>
  //           <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
  //             {description}
  //           </Title>
  //           {/* {customer_email && (
  //             <Paragraph style={{ marginBottom: 0 }} ellipsis>
  //               {customer_email}
  //             </Paragraph>
  //           )} */}
  //         </Typography>
  //       </Col>
  //     </Row>
  //   ),
  // },
  {
    title: 'Customer',
    dataIndex: 'name',
    ellipsis: true,
    sorter: true,
    render: (_, { id, name, description }) => (
      <Typography>
        <Paragraph
          // level={5}
          strong={true}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          {name}
        </Paragraph>
        {description && (
          <Paragraph style={{ marginBottom: 0 }} ellipsis>
            {description}
          </Paragraph>
        )}
      </Typography>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
    render: (email) => (
      <Typography>
        <Paragraph copyable={!!email} ellipsis>
          {email || '-'}
        </Paragraph>
      </Typography>
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    sorter: true,
    render: (email) => (
      <Typography>
        <Paragraph ellipsis>{email || '-'}</Paragraph>
      </Typography>
    ),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    align: 'right',
    render: (createdAt, { metadata }) => {
      return metadata?.demo ? (
        // <Badge count={<Info size={16} style={{ color: '#f5222d' }} />} />
        <DemoItemTag />
      ) : (
        <p>{formatDate(createdAt)}</p>
      )
    },
  },
  {
    width: 150,
    title: '',
    dataIndex: 'integration',
    align: 'center',
    render: (_, { externalIds, metadata = {} }) => {
      if (externalIds?.quickbooks) {
        return (
          <QuickbooksIcon
            showBadge={true}
            lastUpdated={metadata?.quickbooks_last_updated_time}
            quickbooksId={externalIds?.quickbooks}
          />
        )
      }
    },
    // valueType: 'dateRange',
    // hideInTable: true,
    // search: false,
    // search: {
    //   transform: (value) => {
    //     return {
    //       createdAtStart: value[0],
    //       createdAtEnd: value[1],
    //     }
    //   },
    // },
  },
]

export const CustomersTable = ({
  data = [],
  tableProps: { loading } = {},
  selectedId,
  onCustomerClick = () => undefined,
  onCustomerDelete = () => undefined,
  onChange = () => undefined,
}: CustomersTableProps) => {
  const actionRef = useRef<ActionType>()
  let navigate = useNavigate()

  return (
    <CustomersTableStyled>
      <Table<CustomerDetailFragment>
        columns={columns}
        dataSource={data?.map((customer: any) => ({
          key: customer.id,
          onDelete: onCustomerDelete,
          ...customer,
        }))}
        onRow={(row, rowIndex) => {
          return {
            onClick: (event) => {
              onCustomerClick(row, event)
            },
          }
        }}
        onChange={onChange}
        rowKey='id'
        loading={loading}
        pagination={false}
      />
    </CustomersTableStyled>
  )
}

CustomersTable.displayName = 'CustomersTable'
