import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { ProForm, ProFormDigit } from '@ant-design/pro-components'
import {
  Form,
  InputNumber,
  SelectProps,
  Select,
  Divider,
  Row,
  Col,
  Button,
  Typography,
} from 'antd'
import React, { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { DiscountInputManagerStyled } from './styles'

export interface DiscountInputManagerProps<ValueType = any> {
  createButtonVisible?: boolean
  createButtonDisabled?: boolean
  onApplyDiscount?: (discount: any) => void
  onAddClick?: () => void
}

export const DiscountInputManager = <
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any
>({
  createButtonVisible = false,
  createButtonDisabled = false,
  onApplyDiscount = () => {},
  onAddClick = () => {},
  ...props
}: DiscountInputManagerProps<ValueType>) => {
  const [discountAmount, setDiscountAmount] = React.useState(null)

  const handleDiscountChange = (value?: any) => {
    setDiscountAmount(value)
  }
  const handleApplyDiscount = () => {
    onApplyDiscount(discountAmount)
    setDiscountAmount(null)
  }

  return (
    <DiscountInputManagerStyled>
      <Row gutter={16}>
        <Col span={16}>
          <InputNumber
            value={discountAmount}
            width='md'
            placeholder='Discount Amount'
            precision={2}
            style={{ width: '100%' }}
            onChange={handleDiscountChange}
            data-testid='discount-input'
          />
        </Col>
        <Col span={8}>
          <Button
            style={{ display: 'block', width: '100%' }}
            onClick={handleApplyDiscount}
            disabled={createButtonDisabled}
            data-testid='discount-apply-button'
          >
            + Apply
          </Button>
        </Col>
      </Row>
    </DiscountInputManagerStyled>
  )
}

DiscountInputManager.displayName = 'DiscountInputManager'
