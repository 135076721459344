import { LinkOutlined } from '@ant-design/icons'
import { Tooltip, Badge, Typography, Space } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { QuickbooksIconStyled } from './styles'
import { formatDate } from '../../utils'
import { QUICKBOOKS_LINK_URL } from '../../config/default'

const quickbooksIcon = `${process.env.PUBLIC_URL}/assets/integrations/quickbooks/icon.png`

export interface QuickbooksIconProps {
  showBadge?: boolean
  showLink?: boolean
  customUrl?: string
  lastUpdated?: string
  quickbooksId?: string // Implement link to QB
}

export const QuickbooksIcon = ({
  showBadge,
  showLink,
  customUrl,
  lastUpdated = '',
  quickbooksId,
}: QuickbooksIconProps) => {
  const quickbooksUrl =
    customUrl || `${QUICKBOOKS_LINK_URL}/customerdetail?nameId=${quickbooksId}`
  const content = showBadge ? (
    // <Badge
    //   color={theme.colors.white}
    //   text={

    //   }
    //   className='quickbooks-badge'
    // />
    <div className='quickbooks-badge'>
      <Space align='center'>
        <img
          style={{ display: 'flex', width: '16px', height: '16px' }}
          src={quickbooksIcon}
          alt='Quickbooks item'
        />
        quickbooks
        {showLink && quickbooksId && (
          <LinkOutlined style={{ display: 'flex', marginLeft: '4px' }} />
        )}
      </Space>
    </div>
  ) : (
    <div style={{ width: 16, height: 16 }}>
      <img
        style={{ width: '100%', height: '100%' }}
        src={quickbooksIcon}
        alt='Quickbooks item'
      />
    </div>
  )

  return (
    <QuickbooksIconStyled>
      {lastUpdated ? (
        <Tooltip
          title={`Updated on ${formatDate(
            new Date(lastUpdated).getTime(),
            {},
            true
          )} `}
          placement='left'
        >
          {quickbooksId ? (
            <a href={quickbooksUrl} target='_blank' rel='noopener noreferrer'>
              {content}
            </a>
          ) : (
            content
          )}
        </Tooltip>
      ) : quickbooksId ? (
        <a href={quickbooksUrl} target='_blank' rel='noopener noreferrer'>
          {content}
        </a>
      ) : (
        content
      )}
    </QuickbooksIconStyled>
  )
}

QuickbooksIcon.displayName = 'QuickbooksIcon'
