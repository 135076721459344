import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialBulkCreateItemTableStyled = styled.div`
  .ant-tag {
    margin: 0;
  }
  .skipped-row td {
    opacity: 0.5;
    background-color: #f5f5f5;
    text-decoration: line-through;
  }
  .ant-table-cell-with-append {
    padding-right: 0 !important;
    padding-left: 0 !important;

    button {
      margin: 0 !important;
    }
  }

  .editable-cell .editable-cell-value-wrap {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px dashed ${(props) => props.theme.colors.border};
  }
`
