import { DollarOutlined } from '@ant-design/icons'
import { Popover, Space, Typography } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { FixedPriceProfitCostIconStyled } from './styles'
import { formatMoney } from '../../utils'

export interface FixedPriceProfitCostIconProps {
  title?: string
  cost?: number
  profit?: number
}

export const FixedPriceProfitCostIcon = ({
  title,
  cost = 0,
  profit = 0,
}: FixedPriceProfitCostIconProps) => {
  return (
    <FixedPriceProfitCostIconStyled>
      <Popover
        title={title}
        content={
          <Space direction='vertical'>
            <Typography.Text>Labor cost: {formatMoney(cost)}</Typography.Text>
            <Typography.Text>Profit: {formatMoney(profit)}</Typography.Text>
          </Space>
        }
      >
        <DollarOutlined style={{ fontSize: '16px' }} />
      </Popover>
    </FixedPriceProfitCostIconStyled>
  )
}

FixedPriceProfitCostIcon.displayName = 'FixedPriceProfitCostIcon'
