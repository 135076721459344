import { EllipsisOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Typography, Dropdown, Space, message } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import * as React from 'react'

import { InvoicesTableStyled } from './styles'
import { StatusTag } from '../StatusTag'
import { QuickbooksIcon } from '../QuickbooksIcon'
import { formatMoney, formatDate } from '../../utils'
import { QUICKBOOKS_LINK_URL } from '../../config/default'

const { Title, Paragraph } = Typography

interface InvoiceDetal {
  id: string
  number: string
  status: string
  total: number
  quickbooksId: string
  due_date: number
  createdAt: number
}

export interface InvoicesTableProps {
  data: any // TODO: InvoiceDetalFragment[]
  selectedId?: string
}

export const InvoicesTable = ({
  data = [],
  selectedId,
}: InvoicesTableProps) => {
  const [tableData, setTableData] = React.useState<InvoiceDetal[]>(data)

  React.useEffect(() => {
    setTableData(
      data.map((invoice: any) => ({
        key: invoice.id,
        ...invoice,
      }))
    )
  }, [data])

  const rowActions = [
    {
      key: 'view',
      label: 'View Invoice',
      icon: <EditOutlined />,
      danger: false,
      'data-testid': 'view-quickbooks-invoice-button',
    },
  ]

  const tableColumns: ColumnsType<InvoiceDetal> = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (createdAt: number) => formatDate(createdAt),
    },
    {
      title: '',
      dataIndex: 'viewInvoice',
      key: 'viewInvoice',
      align: 'right',
      render: (_, { number }) => {
        return (
          <QuickbooksIcon
            showBadge={true}
            showLink={true}
            quickbooksId={number}
            customUrl={`${QUICKBOOKS_LINK_URL}/invoice?txnId=${number}`}
          />
        )
      },
    },
  ]

  return (
    <>
      <InvoicesTableStyled>
        <Table<any>
          rowKey='number'
          dataSource={tableData}
          showHeader={true}
          pagination={false}
          columns={tableColumns}
          data-testid='invoices-table'
        />
      </InvoicesTableStyled>
    </>
  )
}

InvoicesTable.displayName = 'InvoicesTable'
