import {
  CheckCircleOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  SaveOutlined,
  EditOutlined,
  UndoOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  Table,
  Tag,
  Button,
  Tooltip,
  Space,
  Form,
  Input,
  InputNumber,
  Typography,
} from 'antd'
import type { GetRef, InputRef, TableProps } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import * as React from 'react'

import {
  dollarInputFormatter,
  dollarInputParser,
  formatCentsToDollars,
} from '../../../utils'
import { theme } from '../../../styles/themes/default'
import { MaterialBulkCreateItemTableStyled } from './styles'
import { Target, Tool } from 'react-feather'

type FormInstance<T> = GetRef<typeof Form<T>>

interface EditableRowProps {
  index: number
}

interface DataType {
  key: React.Key
  editable?: boolean
  name: string
  description: string
  created: boolean
  skipped: boolean
  saved_price_to: string
  mutationResponse?: {
    created?: boolean
    error?: boolean
    errorMessage?: string
  }
}

export interface MaterialBulkCreateItemTableProps {
  items?: any[]
  loading?: boolean
  onRowEdit?: (row: any) => void
  onToggleSkipRow?: (rowIndex: number | undefined, skip?: boolean) => void
}

const EditableContext = React.createContext<FormInstance<any> | null>(null)
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell: React.FC<React.PropsWithChildren<any>> = ({
  title,
  editable,
  children,
  dataIndex,
  inputType,
  record,
  required,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = React.useState(false)
  const inputRef = React.useRef<InputRef>(null)
  const form = React.useContext(EditableContext)!

  React.useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    if (record.skipped) return

    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()

      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo: any) {
      console.log('Save failed:', errInfo)
      handleSave(null, errInfo.errorFields)
    }
  }

  let childNode = children

  if (editable) {
    if (editing) {
      switch (inputType) {
        case 'price':
          childNode = (
            <Form.Item
              style={{ margin: 0, width: '100%' }}
              name={dataIndex}
              rules={[
                {
                  required,
                  message: 'Missing price',
                },
              ]}
            >
              <InputNumber
                // @ts-ignore
                ref={inputRef}
                placeholder='Price'
                decimalSeparator='.'
                formatter={dollarInputFormatter}
                parser={dollarInputParser}
                precision={2}
                onPressEnter={save}
                onBlur={save}
                style={{ margin: 0, width: '100%' }}
              />
            </Form.Item>
          )
          break
        default:
          childNode = (
            <Form.Item
              style={{ margin: 0, width: '100%' }}
              name={dataIndex}
              rules={[
                {
                  required,
                  message: `${title} is required.`,
                },
              ]}
            >
              <Input
                ref={inputRef}
                onPressEnter={save}
                onBlur={save}
                style={{ margin: 0, width: '100%' }}
              />
            </Form.Item>
          )
          break
      }
    } else {
      if (inputType === 'price' && !record.formatted_unit_amount) {
        childNode = (
          <div
            className='editable-cell-value-wrap'
            onClick={toggleEdit}
            onFocus={toggleEdit}
            tabIndex={0}
          >
            $0.00
          </div>
        )
      } else if (inputType !== 'price' && record.matchedProduct) {
        childNode = (
          <div tabIndex={0}>
            <a
              href={`/materials/${record.matchedProduct.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <Typography.Text ellipsis={{ tooltip: `${children[1]}` }}>
                {children}
              </Typography.Text>
            </a>
          </div>
        )
      } else {
        childNode = (
          <div
            className='editable-cell-value-wrap'
            onClick={toggleEdit}
            onFocus={toggleEdit}
            tabIndex={0}
          >
            <Typography.Text ellipsis={{ tooltip: `${children[1]}` }}>
              {children}
            </Typography.Text>
          </div>
        )
      }
    }
  }

  return <td {...restProps}>{childNode}</td>
}

export const MaterialBulkCreateItemTable = ({
  items = [],
  loading = false,
  onRowEdit = () => {},
  onToggleSkipRow = () => {},
}: MaterialBulkCreateItemTableProps) => {
  const [dataSource, setDataSource] = React.useState<any>(items)

  React.useEffect(() => {
    setDataSource(items)
  }, [items])

  const defaultColumns:
    | (ColumnsType[number] & {
        editable?: boolean
        inputType?:
          | 'text'
          | 'percent'
          | 'price'
          | 'quantity'
          | 'markup'
          | 'product'
        dataIndex?: string | string[]
        required?: boolean
      })[] = [
    {
      title: 'Item',
      dataIndex: 'formatted_name',
      width: '35%',
      ellipsis: true,
      editable: true,
      required: true,
      className: 'editable-cell',
    },
    {
      title: 'Description',
      dataIndex: 'formatted_description',
      width: '25%',
      ellipsis: true,
      editable: true,
      required: false,
      className: 'editable-cell',
      render: (text: any, record) => {
        if (record.matchedProduct) {
          return text
        }

        return text ? text : '-'
      },
    },
    {
      title: 'Cost',
      dataIndex: 'formatted_unit_amount',
      align: 'right',
      editable: true,
      required: true,
      inputType: 'price',
      className: 'editable-cell',
      render: (text: any) => {
        return text ? `$${formatCentsToDollars(text)}` : ''
      },
    },
    {
      title: 'Unit',
      dataIndex: 'formatted_unit',
      align: 'center',
      editable: true,
      required: true,
      className: 'editable-cell',
    },
    {
      title: 'Action',
      align: 'center',
      render: (_, record) => {
        let tagComponent = (
          <Tooltip title='Material will be created'>
            <Tag color='green' icon={<CheckCircleOutlined />} />
          </Tooltip>
        )

        if (record.matchedProduct) {
          tagComponent = (
            <Tooltip title='Price record will be saved to this material'>
              <Tag color='cyan' icon={<SaveOutlined />} />
            </Tooltip>
          )
        } else if (record.skipped) {
          tagComponent = (
            <Tooltip title='Skip saving this product'>
              <Tag color='default' icon={<StopOutlined />} />
            </Tooltip>
          )
        }

        return tagComponent
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: 30,
      // eslint-disable-next-line react/display-name
      render: ({ id, uploadedDocument, onViewDocument }, record, rowIndex) => {
        // if (loading) {
        //   return (
        //     <Button
        //       type='text'
        //       icon={<LoadingOutlined />}
        //       size='small'
        //       style={{ cursor: 'default' }}
        //     />
        //   )
        // }

        if (record.mutationResponse?.created) {
          return (
            <Tooltip placement='left' title='Item saved'>
              <Button
                type='text'
                icon={<CheckOutlined style={{ color: theme.colors.success }} />}
                size='small'
                style={{ cursor: 'default' }}
              />
            </Tooltip>
          )
        }

        if (record.mutationResponse?.error) {
          return (
            <Tooltip
              placement='left'
              title={record.mutationResponse?.errorMessage}
            >
              <Button
                type='text'
                icon={
                  <ExclamationCircleOutlined
                    style={{ color: theme.colors.error }}
                  />
                }
                size='small'
                style={{ cursor: 'default' }}
              />
            </Tooltip>
          )
        }

        if (record.skipped) {
          return (
            <Space>
              <Tooltip placement='left' title='Undo'>
                <Button
                  type='text'
                  icon={<UndoOutlined />}
                  size='small'
                  onClick={() => onToggleSkipRow(rowIndex, false)}
                />
              </Tooltip>
            </Space>
          )
        } else {
          return (
            <Space>
              <Tooltip placement='left' title='Skip this item'>
                <Button
                  type='text'
                  icon={<StopOutlined />}
                  size='small'
                  onClick={() => onToggleSkipRow(rowIndex, true)}
                />
              </Tooltip>
            </Space>
          )
        }
      },
    },
  ]

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable:
          col.editable && !record.skipped && !record?.mutationResponse?.created,
        className: !record.skipped ? col.className : '',
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        required: col.required,
        handleSave: onRowEdit,
      }),
    }
  })

  return (
    <MaterialBulkCreateItemTableStyled>
      <Table
        // @ts-ignore
        columns={columns}
        components={components}
        dataSource={dataSource}
        size='small'
        pagination={false}
        rowKey='key'
        rowClassName={(record) => {
          return record.skipped ? 'skipped-row' : ''
        }}
      />
    </MaterialBulkCreateItemTableStyled>
  )
}

MaterialBulkCreateItemTable.displayName = 'MaterialBulkCreateItemTable'
