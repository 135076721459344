import React from 'react'
import type { TourProps } from 'antd'
import { Space } from 'antd'

export const getEstimateOnboardingSteps = (targetRefs: any) => {
  const steps: TourProps['steps'] = [
    {
      title: 'Welcome to VoltPro!',
      description: (
        <Space direction='vertical'>
          <p>
            The easiest way for contractors and service professionals to create
            incredible estimates.
          </p>
          <p style={{ marginTop: 0 }}>
            Lets take a quick tour of creating a simple estimate.
          </p>
        </Space>
      ),
      cover: (
        <img
          alt='welcome_onboarding_1.png'
          src='https://voltappstorage01.blob.core.windows.net/public-assets/app/welcome_onboarding_1.png'
        />
      ),
    },
    {
      title: 'Estimate information',
      description:
        'This is where you fill out the basic information of your estimate. This includes the customer, estimate number, and due dates.',
      // @ts-ignore
      target: () => targetRefs.information_section,
    },
    {
      title: 'Project scope',
      description:
        'The project scope section is where you define the details about your job for your customer. This will show up on the estimate PDF.',
      target: () => {
        // @ts-ignore
        return targetRefs.project_scope
      },
    },
    {
      title: 'Add items',
      description: (
        <Space direction='vertical'>
          <p>
            Here is where you add the items that make up your estimate. You can
            add items from your materials catalog, through assemblies, or create
            new custom items only for this estimate. You can even add section
            titles to keep everything organized.
          </p>
          <p>
            Hover over the &lsquo;...&rsquo; button to see more options. We have
            added some demonstration data for you to test with.
          </p>
        </Space>
      ),
      target: () => {
        // @ts-ignore
        return targetRefs.items_section
      },
    },
    {
      title: 'Track profit, cost, and totals',
      description: (
        <Space direction='vertical'>
          <p>
            As you add items to your estimate, you can see the profit, cost, and
            totals update in real-time. This will help you make sure you are
            pricing your work correctly.
          </p>
          <p>
            Hover over the profit and cost labels to see a breakdown of the
            calculations.
          </p>
        </Space>
      ),
      target: () => {
        // @ts-ignore
        return targetRefs.totals_section
      },
    },
    {
      title: 'Create',
      description:
        'When you are ready, create the estimate by hitting save. This will not send anything to the customer.',
      target: () => {
        // @ts-ignore
        return targetRefs.save_button
      },
    },
  ]

  return steps
}
