import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components'
import {
  CustomerDetailFragment,
  UpdateCustomerMutation,
  CreateCustomerMutation,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
} from '../../graphql/_generated-hooks'
import { Button, Form, message, Row, Col, Input } from 'antd'
import React, { useMemo, useRef, useState } from 'react'

import { setApiFormErrors, isObjectEmpty } from '../../utils'
import { CustomerFormDrawerStyled } from './styles'

const DRAWER_WIDTH = 450

export interface CustomerFormDrawerProps {
  customer?: CustomerDetailFragment
  onCustomerSave?: (
    data: CreateCustomerMutation | UpdateCustomerMutation | null | undefined
  ) => void
  onClose?: () => void
  open?: boolean
}

export interface CustomerFormSchema {
  name: string
  email: string
  phone: string
  description: string
  address_city: string
  address_country: string
  address_line1: string
  address_line2: string
  address_postal_code: string
  address_state: string
}

export const CustomerFormDrawer = ({
  customer,
  onCustomerSave = () => {},
  onClose = () => {},
  open,
}: CustomerFormDrawerProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<{ name: string; company: string }>()
  const [createCustomerMutation, { loading: creatingCustomer }] =
    useCreateCustomerMutation()
  const [updateCustomerMutation, { loading: updatingCustomer }] =
    useUpdateCustomerMutation()

  const customerHandledRef = React.useRef(false)

  React.useEffect(() => {
    if (
      customer &&
      !isObjectEmpty(customer) &&
      open &&
      !customerHandledRef.current
    ) {
      form.setFieldsValue({
        ...customer,
      })

      customerHandledRef.current = true
    }
    if (!open) {
      customerHandledRef.current = false
    }
  }, [customer, open])

  const handleSubmitCustomer = async (values: CustomerFormSchema) => {
    if (customer) {
      handleEditCustomer(values)
    } else {
      handleCreateCustomer(values)
    }
  }

  const handleEditCustomer = async (values: CustomerFormSchema) => {
    if (!customer || !customer.id) {
      message.error('No customer ID')
      return onClose()
    }

    updateCustomerMutation({
      variables: {
        id: customer?.id,
        input: values,
      },
      onCompleted({ updateCustomer }: any = {}) {
        messageApi.success('Success: Customer updated successfully!')

        onCustomerSave(updateCustomer)
      },
      onError(error, clientOptions) {
        console.log('error ', error)
        setApiFormErrors(error.graphQLErrors, form)
        messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      },
    })
  }
  const handleCreateCustomer = async (values: CustomerFormSchema) => {
    createCustomerMutation({
      variables: {
        input: values,
      },
      onCompleted({ createCustomer }: any = {}) {
        messageApi.success('Success: Customer created successfully!')

        onCustomerSave(createCustomer)
      },
      onError(error, clientOptions) {
        console.log('error ', error)
        setApiFormErrors(error.graphQLErrors, form)
        messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      },
    })
  }

  return (
    <CustomerFormDrawerStyled>
      {contextHolder}
      <DrawerForm<CustomerFormSchema>
        title={`${customer ? 'Edit' : 'Create'} Customer`}
        form={form}
        open={open}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          maskClosable: false,
          onClose,
          width: DRAWER_WIDTH,
        }}
        submitTimeout={2000}
        onFinish={handleSubmitCustomer}
        submitter={{
          submitButtonProps: {
            loading: creatingCustomer || updatingCustomer,
            id: 'submit-customer-form-button',
          },
          searchConfig: {
            submitText: 'Save',
            resetText: 'Cancel',
          },
        }}
      >
        <ProForm.Group>
          <ProFormText
            fieldProps={{
              'data-1p-ignore': '',
            }}
            name='name'
            width='xl'
            rules={[
              {
                required: true,
              },
            ]}
            label='Name'
            placeholder='Example Company'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText
            fieldProps={{
              'data-1p-ignore': '',
            }}
            rules={[
              {
                required: true,
              },
            ]}
            width='xl'
            name='email'
            label='Email Address'
            placeholder='trades@mytradescompany.com'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText
            width='xl'
            name='phone'
            label='Phone Number'
            placeholder='+1 (555) 888-1234'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText
            width='xl'
            name='address_line1'
            label='Address'
            placeholder='Address Line 1'
          />
        </ProForm.Group>

        <ProForm.Group>
          <ProFormText
            width='xl'
            name='address_line2'
            placeholder='Address Line 2'
          />
        </ProForm.Group>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <ProFormText
              width='sm'
              name='address_city'
              label='City'
              placeholder='Vanvouer'
            />
          </Col>
          <Col span={12}>
            <ProFormText
              width='sm'
              name='address_state'
              label='State/Province'
              placeholder='British Columbia'
            />
          </Col>
          <Col span={12}>
            <ProFormText
              width='sm'
              name='address_country'
              label='Country'
              placeholder='Canada'
            />
          </Col>
          <Col span={12}>
            <ProFormText
              width='sm'
              name='address_postal_code'
              label='Postal Code'
              placeholder='V5Z XXX'
            />
          </Col>
        </Row>
      </DrawerForm>
    </CustomerFormDrawerStyled>
  )
}

CustomerFormDrawer.displayName = 'CustomerFormDrawer'
