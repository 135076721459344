import {
  PlusOutlined,
  UserAddOutlined,
  EyeOutlined,
  DownloadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  RightCircleOutlined,
  QuestionCircleOutlined,
  UserDeleteOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  Layout,
  Descriptions,
  Modal,
  Tag,
  Dropdown,
  message,
  Badge,
} from 'antd'
import { FormInstance, useForm } from 'antd/lib/form/Form'
import { Content } from 'antd/lib/layout/layout'
import { ColumnsType } from 'antd/lib/table'
import * as React from 'react'
import { useParams, redirect, useNavigate } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'

import {
  SortOrderInput,
  SortFieldInput,
  useGetProductQuery,
  ProductDetailFragment,
  useGetPricesQuery,
  PriceDetailFragment,
  useDeleteProductMutation,
  useGetMeQuery,
  ProductTypeFieldInput,
  // MaterialsDetailViewFragment,
} from '../../graphql/_generated-hooks'
import {
  formatMoney,
  formatDate,
  formatTime,
  memoryStore,
  getPriceAgeStatus,
} from '../../utils'
import { PageTitle } from '../../components/PageTitle'
import { PriceTable } from '../../components/PriceTable'
import { TaxTotalSummary } from '../../components/TaxTotalSummary'
import { DemoItemTag } from '../../components/DemoItemTag'
import { PriceCreateFormDrawer } from '../../components/PriceCreateFormDrawer'
import { MaterialFormDrawer } from '../../components/MaterialFormDrawer'
import { PriceAgeTag } from '../../components/PriceAgeTag'
import { SkeletonLoader } from '../../components/SkeletonLoader'
import { PDFViewer } from '../../components/PDFViewer'
import { PriceHistoryChart } from '../../components/PriceHistoryChart'
import { CategoryCreateFormDrawer } from '../../components/CategoryCreateFormDrawer'
import { DistributorCreateFormDrawer } from '../../components/DistributorCreateFormDrawer'
import { ServiceItemBadge } from '../../components/ServiceItemBadge'
import { MaterialsDetailViewStyled } from './styles'
import { theme } from '../../styles/themes/default'

const { Paragraph } = Typography

export interface MaterialsDetailViewProps {}

const materialActions = [
  {
    id: 'delete-material-button',
    key: 'delete',
    label: 'Delete',
    icon: <DeleteOutlined />,
    danger: true,
  },
]

export const MaterialsDetailView = ({ ...props }: MaterialsDetailViewProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [activeProduct, setActiveProduct] =
    React.useState<ProductDetailFragment>({ id: '', name: '' })
  const [latestPrice, setLatestPrice] = React.useState<PriceDetailFragment>({
    id: '',
    name: '',
    unit_amount: 0,
  })
  const [editPriceObject, setEditPriceObject] =
    React.useState<PriceDetailFragment>()
  const [createPrice, setCreatePrice] = React.useState(false)
  const [createCategory, setCreateCategory] = React.useState(false)
  const [createDistributor, setCreateDistributor] = React.useState(false)
  const [createdCategory, setCreatedCategory] = React.useState(null)
  const [createdDistributor, setCreatedDistributor] = React.useState(null)
  const [updateMaterial, setUpdateMaterial] = React.useState(false)
  const [isServiceMaterial, setIsServiceMaterial] = React.useState(false)
  const [viewDocument, setViewDocument] = React.useState(false)
  const [viewDocumentFileUrl, setViewDocumentFileUrl] = React.useState('')
  const [viewDocumentFileMimeType, setViewDocumentFileMimeType] =
    React.useState('')

  // State
  const removeMaterial = useAppStore((state: any) => state.removeMaterial)

  let navigate = useNavigate()
  let { id } = useParams()

  if (!id) {
    redirect('/materials')
  }

  // Get Material
  const [deleteProductMutation, { data, loading: isDeletingMaterial, error }] =
    useDeleteProductMutation()
  const { loading: isLoadingMaterial, refetch: refetchMaterial } =
    useGetProductQuery({
      variables: { id: id || '' },
      onCompleted: ({ product } = {}) => {
        if (!product) {
          return navigate(`/materials?message=Product not found&status=info`)
        }

        setActiveProduct(product)
        setIsServiceMaterial(product.type === 'service')
      },
    })

  // Get Me
  const { data: { me } = {} } = useGetMeQuery()
  // Get Prices
  const {
    data: priceData,
    loading: isLoadingPrices,
    refetch: refetchPrices,
    updateQuery: updatePrices,
  } = useGetPricesQuery({
    variables: {
      input: {
        productId: id || '',
        sort: {
          field: SortFieldInput.InvoicedAt,
          order: SortOrderInput.Desc,
        },
      },
    },
  })

  React.useEffect(() => {
    const { prices } = priceData || {}
    if (!prices || !prices.length || !prices[0]) return

    setLatestPrice(prices[0] || { id: '', name: '', unit_amount: 0 })
  }, [priceData])

  const onMaterialServiceSave = async (data: any) => {
    setUpdateMaterial(false)
    const {
      data: { product },
    } = await refetchMaterial()

    if (data.type === ProductTypeFieldInput.Service) {
      refetchPrices() // TODO can definitely optimize this in the future
    }

    if (product) setActiveProduct(product)
  }

  const onActionMenuClick = (e: any) => {
    const info = e.key
    switch (info) {
      case 'delete':
        Modal.confirm({
          title: `Delete ${activeProduct.name}?`,
          content:
            'Are you sure you want to delete the item? This action cannot be undone.',
          okText: 'Confirm',
          okType: 'danger',
          cancelText: 'Cancel',
          okButtonProps: {
            loading: isDeletingMaterial,
          },
          onOk() {
            deleteProductMutation({
              variables: {
                id: activeProduct.id,
              },
            }).then(() => {
              removeMaterial()
              navigate(
                `/materials?message=${activeProduct.name} has been deleted&status=success`
              )
            })
          },
        })
        break
    }
  }

  const onDistributorSave = (distributor: any) => {
    setCreatedDistributor(distributor)
    setCreateDistributor(false)
  }
  const onCategorySave = (category: any) => {
    setCreatedCategory(category)
    setCreateCategory(false)
  }
  const handleDistributorFormClose = () => {
    setCreateDistributor(false)
  }
  const handleCategoryFormClose = () => {
    setCreateCategory(false)
  }

  return (
    <MaterialsDetailViewStyled>
      {contextHolder}
      <Content style={{ padding: '2em' }}>
        <Row align='middle'>
          <Col flex='1 0'>
            <PageTitle
              title={activeProduct.name || 'N/A'}
              subtitle={activeProduct.description || ''}
              badge={isServiceMaterial && <ServiceItemBadge />}
            />
          </Col>
          {!isLoadingMaterial && (
            <Col flex='1 0' style={{ textAlign: 'right' }}>
              <Space>
                {activeProduct?.metadata?.demo ? (
                  <DemoItemTag />
                ) : (
                  <Dropdown.Button
                    data-testid='edit-material-more-button'
                    onClick={() => setUpdateMaterial(true)}
                    menu={{
                      items: materialActions,
                      onClick: onActionMenuClick,
                    }}
                  >
                    <EditOutlined />
                    Edit
                  </Dropdown.Button>
                )}
              </Space>
            </Col>
          )}
        </Row>
      </Content>
      <Layout style={{ padding: '0 2em' }}>
        <Content
          style={{ marginBottom: '2em', background: '#fff', padding: '24px' }}
        >
          {isLoadingMaterial && <SkeletonLoader pageType='detail' rows={10} />}

          {!isLoadingMaterial && (
            <>
              {!isServiceMaterial && (
                <Row gutter={[16, 24]} justify='space-between'>
                  <Col span={12}>
                    <Descriptions layout='vertical'>
                      <Descriptions.Item label='SKU/UPS'>
                        {activeProduct.sku ? (
                          <Paragraph
                            style={{ fontSize: '16px' }}
                            copyable={true}
                            data-testid='material-detail-sku'
                          >
                            {activeProduct.sku}
                          </Paragraph>
                        ) : (
                          <Paragraph italic>No SKU defined</Paragraph>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  {latestPrice.id && (
                    <Col span={12}>
                      <div style={{ float: 'right' }}>
                        <PriceAgeTag
                          latestPriceDate={latestPrice.invoicedAt}
                          tooltipProps={{ placement: 'left' }}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              )}
              {latestPrice.id && (
                <Row
                  gutter={[16, 24]}
                  justify='space-between'
                  style={{ marginBottom: '4em' }}
                  className='bordered-row'
                >
                  <Col span={8} className='border-right'>
                    <Descriptions layout='vertical'>
                      <Descriptions.Item
                        label={
                          isServiceMaterial ? 'Service Cost' : 'Latest Price'
                        }
                      >
                        <span data-testid='material-detail-unit-amount'>
                          {formatMoney(latestPrice?.unit_amount)}
                          <span
                            style={{ color: '#afafaf', paddingLeft: '10px' }}
                          >
                            /{' '}
                            {isServiceMaterial
                              ? 'per hour'
                              : activeProduct.unit_type}
                          </span>
                        </span>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  {!isServiceMaterial && (
                    <Col span={8} className='border-right'>
                      <Descriptions layout='vertical'>
                        <Descriptions.Item label='Date'>
                          {latestPrice.invoicedAt ? (
                            formatDate(latestPrice.invoicedAt)
                          ) : (
                            <Paragraph italic>N/A</Paragraph>
                          )}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  )}
                  {!isServiceMaterial && (
                    <Col span={8} className='border-right'>
                      <Descriptions layout='vertical'>
                        <Descriptions.Item label='Vendor'>
                          {latestPrice.distributor?.name}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  )}
                  {/* <Col span={6} className='border-right'>
                  <Descriptions layout='vertical'>
                    <Descriptions.Item
                      label={
                        <Space>
                          Tier
                          <Tooltip title='Tiers determine the default markup to be applied to a material/service'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Space>
                      }
                    >
                      Tier 1 (15%)
                    </Descriptions.Item>
                  </Descriptions>
                </Col> */}
                </Row>
              )}
              {activeProduct?.fixed_price && (
                <Row gutter={[16, 24]} style={{ marginBottom: '4em' }}>
                  <Col span={8} className='border-right'>
                    <Descriptions layout='vertical'>
                      <Descriptions.Item label='Service Rate'>
                        <span data-testid='material-detail-default-rate'>
                          {formatMoney(activeProduct?.fixed_price)}
                          <span
                            style={{ color: '#afafaf', paddingLeft: '10px' }}
                          >
                            /{' '}
                            {isServiceMaterial
                              ? 'per hour'
                              : activeProduct.unit_type}
                          </span>
                        </span>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              )}
              {activeProduct.notes && (
                <Row
                  gutter={[16, 24]}
                  justify='space-between'
                  style={{ marginBottom: '4em' }}
                  className='bordered-row'
                >
                  <Col style={{ borderRight: 0 }}>
                    <Descriptions layout='vertical'>
                      <Descriptions.Item label='Notes'>
                        {activeProduct.notes}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              )}

              {!isServiceMaterial && (
                <Row gutter={[16, 24]} style={{ marginBottom: '4em' }}>
                  <Col span={12}>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                      Price history
                    </Typography.Title>
                  </Col>
                  <Col span={12}>
                    <Button
                      type='text'
                      size='small'
                      style={{ float: 'right' }}
                      onClick={() => setCreatePrice(true)}
                      data-testid='add-price-button'
                    >
                      + Add Price Entry
                    </Button>
                  </Col>
                  {priceData?.prices && priceData?.prices?.length > 2 && (
                    <Col span={24}>
                      <PriceHistoryChart
                        data={
                          priceData?.prices
                            ? [...priceData.prices].reverse()
                            : []
                        }
                        dataKey='unit_amount'
                      />
                    </Col>
                  )}
                  <Col span={24}>
                    <PriceTable
                      data={priceData?.prices || []}
                      tableProps={{ loading: isLoadingMaterial }}
                      onPriceEdit={(price) => {
                        setEditPriceObject(price)
                        setCreatePrice(true)
                      }}
                      onViewDocument={(file_url, mime_type) => {
                        setViewDocumentFileUrl(file_url)
                        setViewDocumentFileMimeType(mime_type)
                        setViewDocument(true)
                      }}
                    />
                  </Col>
                </Row>
              )}

              {activeProduct.categories &&
                activeProduct.categories.length > 0 && (
                  <Row
                    gutter={[16, 24]}
                    justify='space-between'
                    style={{ marginBottom: '4em' }}
                    className='bordered-row'
                  >
                    <Col span={24} style={{ borderRight: 0 }}>
                      <Descriptions layout='vertical'>
                        <Descriptions.Item
                          label='Categories'
                          contentStyle={{
                            width: '100%',
                            flexWrap: 'wrap',

                            rowGap: '10px',
                          }}
                        >
                          {activeProduct?.categories?.map(
                            (category, $index) => (
                              <Tag key={$index}>{category?.name || ''}</Tag>
                            )
                          )}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                )}
            </>
          )}
        </Content>
      </Layout>

      <MaterialFormDrawer
        open={updateMaterial}
        onClose={() => setUpdateMaterial(false)}
        onSave={onMaterialServiceSave}
        newCategory={createdCategory}
        onCreateNewCategory={() => {
          setCreateCategory(true)
        }}
        mode='edit'
        material={{
          id: activeProduct.id,
          name: activeProduct.name,
          description: activeProduct.description,
          unit_type: activeProduct.unit_type,
          sku: activeProduct.sku,
          notes: activeProduct.notes,
          categories: activeProduct.categories || [],
          coverage_item: activeProduct.coverage_item,
          coverage_rate: activeProduct.coverage_rate,
          single_use: activeProduct.single_use,
          type: activeProduct.type,
          ...(activeProduct.fixed_price && {
            fixed_price: activeProduct.fixed_price / 100,
          }),
          ...(activeProduct.type === 'service' && {
            unit_amount: priceData?.prices?.[0]?.unit_amount
              ? priceData?.prices?.[0]?.unit_amount / 100
              : undefined,
          }),
          metadata: activeProduct.metadata,
        }}
      />

      <PriceCreateFormDrawer
        productId={activeProduct.id}
        open={createPrice}
        price={editPriceObject}
        newDistributor={createdDistributor}
        onCreateNewDistributor={() => {
          setCreateDistributor(true)
        }}
        onPriceSave={async (data) => {
          setCreatePrice(false)
          setCreatedDistributor(null)
          refetchPrices()
        }}
        onClose={() => {
          setCreatePrice(false)
          setEditPriceObject(undefined)
        }}
      />

      {/* Order matters, this needs to stack on top of the Material create form */}
      <DistributorCreateFormDrawer
        open={createDistributor}
        mode='create'
        width={425}
        onSave={onDistributorSave}
        onClose={handleDistributorFormClose}
      />

      <CategoryCreateFormDrawer
        open={createCategory}
        mode='create'
        width={425}
        onSave={onCategorySave}
        onClose={handleCategoryFormClose}
      />

      <Modal
        open={viewDocument}
        onCancel={() => setViewDocument(false)}
        footer={null}
        width={1000}
        style={{ top: 20, bottom: 20, overflow: 'scroll', textAlign: 'center' }}
      >
        {viewDocumentFileMimeType == 'application/pdf' && (
          <PDFViewer
            fileUrl={`${viewDocumentFileUrl}?${me?.sas_token}`}
            pageWidth={960}
          />
        )}
        {viewDocumentFileMimeType != 'application/pdf' && (
          <img width={960} src={`${viewDocumentFileUrl}?${me?.sas_token}`} />
        )}
      </Modal>
    </MaterialsDetailViewStyled>
  )
}

MaterialsDetailView.displayName = 'MaterialsDetailView'
