import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const VoltProIconStyled = styled.div`
  font-size: ${rem(16)};

  .voltpro-badge {
    font-size: ${rem(12)};
    display: inline-flex;
    padding: 3px 10px;
    border: 1px solid #eee;
    border-radius: 5px;
  }
`
