import { PlusOutlined, ImportOutlined } from '@ant-design/icons'
import { message, Button, Col, Input, Row, Pagination, Space } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'

import {
  useGetCustomersQuery,
  useBulkImportCustomersFromQuickbooksMutation,
} from '../../graphql/_generated-hooks'
import { PageTitle } from '../../components/PageTitle'
import { CustomersTable } from '../../components/CustomersTable'
import { CustomerFormDrawer } from '../../components/CustomerFormDrawer'
import { QuickbooksBulkSyncModal } from '../../components/QuickbooksBulkSyncModal'
import { LockableButton } from '../../components/LockableButton'
import { QuickbooksIcon } from '../../components/QuickbooksIcon'
import { CustomerListViewStyled } from './styles'

const { Search } = Input
const defaultPageSize = 10
const lockReason = 'You need to upgrade to import customers'

export interface CustomerListViewProps {}

export const CustomerListView = ({ ...props }: CustomerListViewProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [createCustomer, setCreateCustomer] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState<any>(null)
  const [customerData, setCustomerData] = React.useState<any>([])
  const [showQuickbooksBulkSyncModal, setShowQuickbooksBulkSyncModal] =
    React.useState(false)

  const activePlan = useAppStore((state: any) => state.plan)
  const quickbooksIntegrationConfig = useAppStore(
    (state: any) => state.quickbooksConfig
  )

  let navigate = useNavigate()

  const {
    data: { customers = { data: [], count: 0 } } = {
      customers: { data: [], count: 0 },
    },
    loading: isLoadingCustomers,
    refetch: refetchCustomers,
    updateQuery: updateCustomers,
  } = useGetCustomersQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: { search: '' },
    },
    onCompleted: ({ customers }) => {
      setCustomerData(customers?.data || [])
    },
  })

  const handleAddCustomer = () => {
    setCreateCustomer(true)
  }
  const handleCustomerFormClose = () => {
    setCreateCustomer(false)
  }

  const handleRowClick = (row: any, event: any) => {
    event.preventDefault()
    navigate(`/customers/${row.id}`, {})
  }

  const onSearch = (value: string) => {
    setSearchQuery(value)
    refetchCustomers({
      input: {
        limit: defaultPageSize,
        search: value,
      },
    })
  }
  const onSort = (field: string, order: string) => {
    refetchCustomers({
      // @ts-ignore
      input: {
        limit: defaultPageSize,
        ...(order && {
          sort: {
            field,
            order: 'ascend' == order ? 'desc' : 'asc',
          },
        }),
      },
    })
  }
  const onPageChange = (page: number, pageSize?: number) => {
    refetchCustomers({
      input: {
        limit: pageSize,
        offset: page * pageSize! - pageSize!,
        ...(searchQuery && { search: searchQuery }),
      },
    })
  }
  const handleShouldLockImportCustomers = () => {
    return !activePlan
  }
  const handleBulkImportCustomers = () => {
    setShowQuickbooksBulkSyncModal(true)
  }
  const handleBulkImportCustomersSuccess = (stats: any) => {
    setShowQuickbooksBulkSyncModal(false)

    if (stats.imported) {
      messageApi.success(
        `Success: ${stats.imported} customers imported successfully!`
      )
    } else {
      messageApi.success(`Success: Customers imported successfully!`)
    }

    refetchCustomers({
      input: {
        limit: defaultPageSize,
      },
    })
  }
  const canImportCustomers = () => {
    return quickbooksIntegrationConfig?.enabled
  }

  return (
    <CustomerListViewStyled>
      {contextHolder}

      <Content style={{ padding: '2em' }}>
        <Row align='middle'>
          <Col flex='1 0'>
            <PageTitle title='Customers' subtitle='Manage customers' />
          </Col>
          <Col flex='1 0' style={{ textAlign: 'right' }}>
            <Space>
              {canImportCustomers() && (
                <Button
                  icon={<QuickbooksIcon />}
                  onClick={handleBulkImportCustomers}
                  data-testid='upload-material-button'
                >
                  Import Customers
                </Button>
              )}
              <Button
                onClick={handleAddCustomer}
                data-testid='add-customer-button'
              >
                <PlusOutlined />
                New Customer
              </Button>
            </Space>
          </Col>
        </Row>
      </Content>
      <Content style={{ padding: '0 2em 2em 2em' }}>
        <Row justify={'end'} style={{ marginBottom: '1em' }}>
          <Col span={12} offset={12}>
            <Space style={{ float: 'right' }}>
              <Search
                placeholder='Search'
                onSearch={onSearch}
                onChange={debounce((e) => {
                  const value = e.target.value
                  if (value && value.length > 1) {
                    onSearch(value)
                  } else if (value === '') {
                    onSearch('')
                  }
                }, 300)}
                allowClear={true}
                style={{ width: 350 }}
                data-testid='customer-search-input'
              />
            </Space>
          </Col>
        </Row>
        <Row style={{ marginBottom: '1em' }}>
          <Col span={24}>
            <CustomersTable
              data={customerData}
              tableProps={{ loading: isLoadingCustomers }}
              onCustomerClick={handleRowClick}
              onCustomerDelete={() => {}}
              onChange={debounce((pagination, filters, sorter) => {
                onSort(sorter.field, sorter.order)
              }, 400)}
              selectedId={''}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Pagination
              defaultCurrent={1}
              defaultPageSize={defaultPageSize}
              showSizeChanger={false}
              total={customers?.count}
              onChange={onPageChange}
              style={{ float: 'right' }}
            />
          </Col>
        </Row>
      </Content>

      <CustomerFormDrawer
        open={createCustomer}
        onClose={handleCustomerFormClose}
        onCustomerSave={() => {
          refetchCustomers()
          handleCustomerFormClose()
        }}
      />

      <QuickbooksBulkSyncModal
        requiresUpgrade={handleShouldLockImportCustomers()}
        open={showQuickbooksBulkSyncModal}
        syncType='customers'
        onCancel={() => setShowQuickbooksBulkSyncModal(false)}
        onBulkSyncSuccess={handleBulkImportCustomersSuccess}
      />
    </CustomerListViewStyled>
  )
}

CustomerListView.displayName = 'CustomerListView'
