const logo =
  'https://voltappstorage01.blob.core.windows.net/public-assets/app/demo_placeholder_logo.png'
const default_creator_name = 'The VoltPro Team'
const default_job_name = 'My First Job'
const QUANTITY_PRECISION = 2 // TODO allow multiple precision levels saved to organization
const MONEY_PRECISION = 2 // TODO allow multiple precision levels saved to organization
const QUICKBOOKS_LINK_URL = 'https://quickbooks.intuit.com/app'
const demo_organization_info = {
  name: 'Your Company Name',
  address_line1: '1234 Citadel Wynd',
  address_line2: 'Suite 100',
  address_city: 'Cityville',
  address_state: 'BC',
  address_country: 'US',
  logo,
}

export {
  logo,
  default_creator_name,
  default_job_name,
  demo_organization_info,
  QUANTITY_PRECISION,
  MONEY_PRECISION,
  QUICKBOOKS_LINK_URL,
}

// TODO really share this with the backend, this is a monorepo after all
const WORK_SCOPE = {
  html: '<p><strong>Scope of Work for 200A Panel Upgrade</strong></p><ul><li><p>The contractor will perform an electrical panel upgrade to a 200A service for enhanced capacity and safety. This work includes:</p><ul><li><p><strong>Removal and Disposal:</strong></p><ul><li><p>Removal of the existing electrical panel and proper disposal in compliance with local regulations.</p></li></ul></li><li><p><strong>Installation:</strong></p><ul><li><p>Installation of a new 200A meter base and service panel.</p></li><li><p>Wiring from the meter to the new panel using appropriate gauge conductors.</p></li><li><p>Installation of new grounding system including two copper rods and connections.</p></li><li><p>Mounting and securing of all new hardware and protective systems.</p></li><li><p>Placement and connection of new circuit breakers as per the load calculations.</p></li></ul></li><li><p><strong>Testing and Verification:</strong></p><ul><li><p>Comprehensive testing of the entire system to ensure functionality and compliance with all local electrical codes.</p></li><li><p>Verification of all connections and safety systems.</p></li></ul></li></ul></li></ul>',
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          text: 'Scope of Work for 200A Panel Upgrade',
          type: 'text',
          marks: [
            {
              type: 'bold',
            },
          ],
        },
      ],
    },
    {
      type: 'bulletList',
      content: [
        {
          type: 'listItem',
          attrs: {
            closed: false,
            nested: false,
          },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  text: 'The contractor will perform an electrical panel upgrade to a 200A service for enhanced capacity and safety. This work includes:',
                  type: 'text',
                },
              ],
            },
            {
              type: 'bulletList',
              content: [
                {
                  type: 'listItem',
                  attrs: {
                    closed: false,
                    nested: false,
                  },
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'Removal and Disposal:',
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'bulletList',
                      content: [
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Removal of the existing electrical panel and proper disposal in compliance with local regulations.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'listItem',
                  attrs: {
                    closed: false,
                    nested: false,
                  },
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'Installation:',
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'bulletList',
                      content: [
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Installation of a new 200A meter base and service panel.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Wiring from the meter to the new panel using appropriate gauge conductors.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Installation of new grounding system including two copper rods and connections.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Mounting and securing of all new hardware and protective systems.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Placement and connection of new circuit breakers as per the load calculations.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'listItem',
                  attrs: {
                    closed: false,
                    nested: false,
                  },
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'Testing and Verification:',
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'bulletList',
                      content: [
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Comprehensive testing of the entire system to ensure functionality and compliance with all local electrical codes.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'listItem',
                          attrs: {
                            closed: false,
                            nested: false,
                          },
                          content: [
                            {
                              type: 'paragraph',
                              content: [
                                {
                                  text: 'Verification of all connections and safety systems.',
                                  type: 'text',
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

export const ONBOARDING_ESTIMATE = {
  name: '',
  number: 'EST-0002',
  description: '',
  contact_name: 'John Doe',
  contact_email: 'john_doe@getvoltpro.com',
  billing_address_city: 'San Francisco',
  billing_address_country: 'US',
  billing_address_line1: '123 Main St',
  billing_address_postal_code: '94111',
  billing_address_state: 'CA',
  estimate_date: new Date(),
  status: 'open',
  work_scope: WORK_SCOPE,
  additional_content: null,
  currency: 'USD',
  metadata: {},
}

export const DEMO_UPLOAD_PAYLOAD = {
  file: {
    status: 'done',
    response: {
      IS_DEMO: true,
      uploadedDocument: {
        file_url:
          'https://voltappstorage01.blob.core.windows.net/public-assets/app/demo_invoice.png',
        mime_type: 'image/png',
        analyzed_output: [
          {
            invoiceDate: {
              kind: 'date',
              value: {},
              content: '01/01/2024',
              confidence: 0.976,
            },
            invoiceId: {
              kind: 'string',
              value: '00001',
              content: '00001',
              confidence: 0.977,
            },
            vendorName: {
              kind: 'string',
              value: 'Electrical Supplies Co',
              content: 'Electrical Supplies Co',
              confidence: 0.818,
            },
            items: {
              kind: 'array',
              values: [
                {
                  kind: 'object',
                  properties: {
                    amount: {
                      kind: 'currency',
                      value: {
                        amount: 185,
                        currencySymbol: '$',
                        currencyCode: 'USD',
                      },
                      content: '$185.00',
                      confidence: 0.922,
                    },
                    description: {
                      kind: 'string',
                      value: 'Electrical Panel',
                      content: 'Electrical Panel',
                      confidence: 0.915,
                    },
                    productCode: {
                      kind: 'string',
                      value: 'PANEL-125',
                      content: 'PANEL-125',
                      confidence: 0.592,
                    },
                    quantity: {
                      kind: 'number',
                      value: 1,
                      content: '1',
                      confidence: 0.919,
                    },
                    unitPrice: {
                      kind: 'currency',
                      value: {
                        amount: 185,
                        currencySymbol: '$',
                        currencyCode: 'USD',
                      },
                      content: '$185.00',
                      confidence: 0.918,
                    },
                  },
                  content: 'Electrical Panel\nPANEL-125\n$185.00\n1\n$185.00',
                  confidence: 0.923,
                },
                {
                  kind: 'object',
                  properties: {
                    amount: {
                      kind: 'currency',
                      value: {
                        amount: 115.92,
                        currencySymbol: '$',
                        currencyCode: 'USD',
                      },
                      content: '$115.92',
                      confidence: 0.922,
                    },
                    description: {
                      kind: 'string',
                      value: 'Circuit Breaker',
                      content: 'Circuit Breaker',
                      confidence: 0.921,
                    },
                    productCode: {
                      kind: 'string',
                      value: 'BREAKER-100A',
                      content: 'BREAKER-100A',
                      confidence: 0.615,
                    },
                    quantity: {
                      kind: 'number',
                      value: 8,
                      content: '8',
                      confidence: 0.919,
                    },
                    unitPrice: {
                      kind: 'currency',
                      value: {
                        amount: 14.49,
                        currencySymbol: '$',
                        currencyCode: 'USD',
                      },
                      content: '$14.49',
                      confidence: 0.92,
                    },
                  },
                  content: 'Circuit Breaker\nBREAKER-100A\n$14.49\n8\n$115.92',
                  confidence: 0.923,
                },
              ],
            },
          },
        ],
      },
      sas_token: '',
      matchedExistingDistributor: {
        id: '9618a37f-1d04-478c-a376-75294712f720',
        slug: 'electrical-supplies-co',
        email: 'contact@electricalsuppliesco.com',
        name: 'Electrical Supplies Co',
        phone: '555-1234',
        description:
          'Leading distributor of electrical components and supplies with a wide range of products for industrial and commercial projects.',
        address_city: 'Industrial City',
        address_country: 'Countryland',
        address_line1: '1234 Industrial Ave',
        address_line2: 'Suite 100',
        address_postal_code: '12345',
        address_state: 'Stateville',
        picture: '',
        active: true,
        metadata: {
          demo: true,
        },
        createdAt: '2024-03-09T16:48:49.202Z',
        updatedAt: '2024-03-09T16:48:49.202Z',
        organizationId: '72f8a753-5a8e-4eb7-af1b-5caee24e3549',
      },
      matchedExistingProducts: {
        'BREAKER-100A': {
          id: 'ca735476-b0db-4dd9-be67-995f21ca58d5',
          name: 'Circuit Breaker',
          description: 'Industrial circuit breaker, 100A.',
          sku: 'BREAKER-100A',
          unit_type: 'unit',
          notes: '100A, 3-phase, suitable for industrial applications.',
          external_id: 'prod-0002',
          active: true,
          metadata: {
            demo: true,
          },
          createdAt: '2024-03-09T16:48:49.470Z',
          updatedAt: '2024-03-09T16:48:49.470Z',
          organizationId: '72f8a753-5a8e-4eb7-af1b-5caee24e3549',
        },
      },
    },
  },
}

export const MAIN_CURRENCIES = [
  { currency: 'CAD', name: 'Canadian Dollar', priority: true },
  { currency: 'USD', name: 'United States Dollar', priority: true },
  { currency: 'GBP', name: 'British Pound Sterling', priority: true },
  { currency: 'EUR', name: 'Euro', priority: true },
  { currency: 'AUD', name: 'Australian Dollar', priority: true },
]
export const OTHER_CURRENCIES = [
  { currency: 'AED', name: 'UAE Dirham' },
  { currency: 'AFN', name: 'Afghan Afghani' },
  { currency: 'ALL', name: 'Albanian Lek' },
  { currency: 'AMD', name: 'Armenian Dram' },
  { currency: 'ANG', name: 'Dutch Guilder' },
  { currency: 'AOA', name: 'Angolan Kwanza' },
  { currency: 'ARS', name: 'Argentine Peso' },
  { currency: 'AWG', name: 'Aruban Florin' },
  { currency: 'AZN', name: 'Azerbaijan Manat' },
  { currency: 'BAM', name: 'Bosnian Mark' },
  { currency: 'BBD', name: 'Barbadian Dollar' },
  { currency: 'BDT', name: 'Bangladeshi Taka' },
  { currency: 'BGN', name: 'Bulgarian Lev' },
  { currency: 'BHD', name: 'Bahraini Dinar' },
  { currency: 'BIF', name: 'Burundi Franc' },
  { currency: 'BMD', name: 'Bermuda Dollar' },
  { currency: 'BND', name: 'Brunei Dollar' },
  { currency: 'BOB', name: 'Bolivian Boliviano' },
  { currency: 'BRL', name: 'Brazilian Real' },
  { currency: 'BSD', name: 'Bahamian Dollar' },
  { currency: 'BTN', name: 'Bhutanese Hgultrum' },
  { currency: 'BWP', name: 'Botswana Pula' },
  { currency: 'BYN', name: 'Belarussian Ruble' },
  { currency: 'BZD', name: 'Belizean Dollar' },
  { currency: 'CDF', name: 'Congolese Franc' },
  { currency: 'CHF', name: 'Swiss Franc' },
  { currency: 'CLP', name: 'Chilean Peso' },
  { currency: 'CRC', name: 'Costa Rica Colon' },
  { currency: 'CUP', name: 'Cuban Peso' },
  { currency: 'CVE', name: 'Cape Verde Escudo' },
  { currency: 'CZK', name: 'Czech Koruna' },
  { currency: 'DJF', name: 'Djibouti Franc' },
  { currency: 'DKK', name: 'Danish Krone' },
  { currency: 'DOP', name: 'Dominican Peso' },
  { currency: 'DZD', name: 'Algerian Dinar' },
  { currency: 'EGP', name: 'Egyptian Pound' },
  { currency: 'ERN', name: 'Eritrean Nakfa' },
  { currency: 'ETB', name: 'Ethiopian Birr' },
  { currency: 'FJD', name: 'Fiji Dollar' },
  { currency: 'FKP', name: 'Falkland Islands Pound' },
  { currency: 'GEL', name: 'Georgian Lari' },
  { currency: 'GHS', name: 'Ghanaian Cedi' },
  { currency: 'GIP', name: 'Gibraltar Pound' },
  { currency: 'GMD', name: 'Gambian Dalasi' },
  { currency: 'GNF', name: 'Guinea Franc' },
  { currency: 'GTQ', name: 'Guatemalan Quetzal' },
  { currency: 'GYD', name: 'Guyana Dollar' },
  { currency: 'HKD', name: 'Hong Kong Dollar' },
  { currency: 'HNL', name: 'Honduran Lempira' },
  { currency: 'HRK', name: 'Croatian Kuna' },
  { currency: 'HTG', name: 'Haiti Gourde' },
  { currency: 'HUF', name: 'Hungarian Forint' },
  { currency: 'IDR', name: 'Indonesian Rupiah' },
  { currency: 'ILS', name: 'Israeli Shekel' },
  { currency: 'INR', name: 'Indian Rupee' },
  { currency: 'IQD', name: 'Iraqi Dinar' },
  { currency: 'IRR', name: 'Iranian Rial' },
  { currency: 'ISK', name: 'Iceland Krona' },
  { currency: 'JMD', name: 'Jamaican Dollar' },
  { currency: 'JOD', name: 'Jordanian Dinar' },
  { currency: 'JPY', name: 'Japanese Yen' },
  { currency: 'KES', name: 'Kenyan Shilling' },
  { currency: 'KGS', name: 'Kyrgyzstani Som' },
  { currency: 'KHR', name: 'Cambodian Riel' },
  { currency: 'KMF', name: 'Comoro Franc' },
  { currency: 'KPW', name: 'North Korean Won' },
  { currency: 'KRW', name: 'South Korean Won' },
  { currency: 'KWD', name: 'Kuwaiti Dinar' },
  { currency: 'KTD', name: 'Cayman Islands Dollar' },
  { currency: 'KZT', name: 'Kazakhstan Tenge' },
  { currency: 'LAK', name: 'Lao Kip' },
  { currency: 'LBP', name: 'Lebanese Pound' },
  { currency: 'LKR', name: 'Sri Lankan Rupee' },
  { currency: 'LRD', name: 'Liberian Dollar' },
  { currency: 'LSL', name: 'Lesotho Loti' },
  { currency: 'LYD', name: 'Libyan Dinar' },
  { currency: 'MAD', name: 'Moroccan Dirham' },
  { currency: 'MDL', name: 'Moldovan Leu' },
  { currency: 'MGA', name: 'Malagasy Ariary' },
  { currency: 'MKD', name: 'Macedonian Denar' },
  { currency: 'MMK', name: 'Myanmar Kyat' },
  { currency: 'MNT', name: 'Mongolian Tugrik' },
  { currency: 'MOP', name: 'Macanese Pataca' },
  { currency: 'MRO', name: 'Mauritanian Ouguiya' },
  { currency: 'MUR', name: 'Mauritius Rupee' },
  { currency: 'MVR', name: 'Maldives Rufiyaa' },
  { currency: 'MWK', name: 'Malawian Kwacha' },
  { currency: 'MXN', name: 'Mexican Peso' },
  { currency: 'MYR', name: 'Malaysian Ringgit' },
  { currency: 'MZN', name: 'Mozambique Metical' },
  { currency: 'NAD', name: 'Namibian Dollar' },
  { currency: 'NGN', name: 'Nigerian Naira' },
  { currency: 'NIO', name: 'Nicaragua Cordoba' },
  { currency: 'NOK', name: 'Norwegian Krone' },
  { currency: 'NPR', name: 'Nepalese Rupee' },
  { currency: 'NZD', name: 'New Zealand Dollar' },
  { currency: 'OMR', name: 'Omani Rial' },
  { currency: 'PAB', name: 'Panama Balboa' },
  { currency: 'PEN', name: 'Peruvian Nuevo Sol' },
  { currency: 'PGK', name: 'Papua New Guinean Kina' },
  { currency: 'PHP', name: 'Philippine Peso' },
  { currency: 'PKR', name: 'Pakistani Rupee' },
  { currency: 'PLN', name: 'Polish Zloty' },
  { currency: 'PYG', name: 'Paraguayan Guarani' },
  { currency: 'QAR', name: 'Qatari Riyal' },
  { currency: 'RON', name: 'Romanian Leu' },
  { currency: 'RSD', name: 'Serbian Dinar' },
  { currency: 'RUB', name: 'Russian Ruble/Rouble' },
  { currency: 'RWF', name: 'Rwanda Franc' },
  { currency: 'SAR', name: 'Saudi Riyal' },
  { currency: 'SBD', name: 'Solomon Islands Dollar' },
  { currency: 'SCR', name: 'Seychelles Rupee' },
  { currency: 'SDG', name: 'Sudanese Pound' },
  { currency: 'SEK', name: 'Swedish Krona' },
  { currency: 'SGD', name: 'Singapore Dollar' },
  { currency: 'SHP', name: 'St. Helena Pound' },
  { currency: 'SLL', name: 'Sierra Leonean Leone' },
  { currency: 'SOS', name: 'Somali Shilling' },
  { currency: 'SRD', name: 'Surinam Dollar' },
  { currency: 'STD', name: 'Sao Tome and Principe Dobra' },
  { currency: 'SVC', name: 'El Salvador Colon' },
  { currency: 'SYP', name: 'Syrian Pound' },
  { currency: 'SZL', name: 'Swaziland Lilangeni' },
  { currency: 'THB', name: 'Thai Baht' },
  { currency: 'TJS', name: 'Tajikistani Somoni' },
  { currency: 'TMT', name: 'Turkmenistani Manat' },
  { currency: 'TND', name: 'Tunisian Dinar' },
  { currency: 'TOP', name: "Tonga Pa'anga" },
  { currency: 'TRY', name: 'Turkish Lira' },
  { currency: 'TTD', name: 'Trinidad & Tobago Dollar' },
  { currency: 'TWD', name: 'Taiwanese Dollar' },
  { currency: 'TZS', name: 'Tanzanian Shilling' },
  { currency: 'UAH', name: 'Ukrainian Hryvnia' },
  { currency: 'UGX', name: 'Ugandan Shilling' },
  { currency: 'UYU', name: 'Uruguayan Peso' },
  { currency: 'UZS', name: 'Uzbekistan Som' },
  { currency: 'VEF', name: 'Venezuealan Bolivar Fuerte' },
  { currency: 'VND', name: 'Vietnam Dong' },
  { currency: 'VUV', name: 'Vanuatu Vatu' },
  { currency: 'WST', name: 'Samoa Tala' },
  { currency: 'XAF', name: 'CFA Franc' },
  { currency: 'XCD', name: 'East Caribbean Dollar' },
  { currency: 'XOF', name: 'CFA Franc' },
  { currency: 'XPF', name: 'CFP Franc' },
  { currency: 'YER', name: 'Yemeni Rial' },
  { currency: 'ZAR', name: 'South African Rand' },
  { currency: 'ZMW', name: 'Zambian Kwacha' },
]
