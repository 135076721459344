import {
  CheckCircleFilled,
  ArrowRightOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Form,
  InputNumber,
  Input,
  Switch,
  Select,
  Tooltip,
  message,
} from 'antd'
import * as React from 'react'

import {
  useGetQuickbooksAccountsQuery,
  useGetQuickbooksAccountsLazyQuery,
  useCreateInvoiceFromEstimateMutation,
  InvoiceItemTypeFieldInput,
} from '../../graphql/_generated-hooks'
import {
  formatMoney,
  setApiFormErrors,
  setGraphQLFormErrors,
} from '../../utils'
import { CustomerDetailCard } from '../CustomerDetailCard'
import { QuickbooksIcon } from '../QuickbooksIcon'

import { EstimateCreateQuickbooksObjModalStyled } from './styles'

export interface EstimateCreateQuickbooksObjModalProps {
  estimate: any
  singleLineItem?: boolean
  open?: boolean
  onCreate?: (quickbooksObject?: any) => void
  onCancel?: () => void
}

export const EstimateCreateQuickbooksObjModal = ({
  estimate,
  singleLineItem,
  open,
  onCreate = () => {},
  onCancel = () => {},
}: EstimateCreateQuickbooksObjModalProps) => {
  const [form] = Form.useForm<any>()
  const [messageApi, contextHolder] = message.useMessage()
  const [accountsList, setAccountsList] = React.useState<any>([])

  const [
    createInvoiceFromEstimate,
    { data, loading: isCreatingInvoice, error },
  ] = useCreateInvoiceFromEstimateMutation()
  const [getQuickbooksAccounts, { loading: loadingAccounts }] =
    useGetQuickbooksAccountsLazyQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ quickbooksAccounts }) => {
        if (quickbooksAccounts?.accounts?.length) {
          setAccountsList(quickbooksAccounts.accounts)
        }
      },
    })

  React.useEffect(() => {
    if (open) {
      getQuickbooksAccounts()
    }
  }, [open])

  // const { data: accountsList, loading: loadingAccounts } =
  //   useGetQuickbooksAccountsQuery({
  //     fetchPolicy: 'no-cache',
  //     notifyOnNetworkStatusChange: true,
  //     onCompleted: (data) => {},
  //   })

  const handleOnOk = async () => {
    try {
      await form.validateFields()
      const formValues = form.getFieldsValue()
      let items = [
        {
          label: formValues.item_name_materials,
          accountId: formValues.item_account_materials,
          type: InvoiceItemTypeFieldInput.Material,
        },
        {
          label: formValues.item_name_labor,
          accountId: formValues.item_account_labor,
          type: InvoiceItemTypeFieldInput.Labor,
        },
      ]

      if (singleLineItem) {
        items = [
          {
            label: formValues.item_name_single,
            accountId: formValues.item_account_single,
            type: InvoiceItemTypeFieldInput.Subtotal,
          },
        ]
      }

      createInvoiceFromEstimate({
        variables: {
          id: estimate.id,
          input: {
            // projectId: null,
            items,
          },
        },
        onCompleted({ createEstimate }: any = {}) {
          onCreate(createEstimate?.data)
          messageApi.success('Success: Invoice created successfully!')
        },
        onError(error, clientOptions) {
          if (error?.graphQLErrors?.[0]?.message) {
            messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
          } else {
            messageApi.error('An error occurred. Please try again.')
          }

          setApiFormErrors(error.graphQLErrors, form)
        },
      })
    } catch (error: any) {
      // GraphQL validation errors will catch here
      if (error && error?.errorFields?.length) {
        // Adds scroll to
        setGraphQLFormErrors(error.errorFields, form)
        messageApi.error(
          `An error occurred: ${error.errorFields[0]?.errors[0]}`
        )
      }
    }
  }

  const handleFormatOptionsList = React.useCallback(
    (data: any) => {
      if (!data?.length) return []

      return data?.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
    },
    [accountsList] // Only update if the initialProductList changes
  )

  const handleSetFirstOption = React.useCallback(
    (data: any) => {
      if (!data?.length) return []

      return data?.[0]?.id
    },
    [accountsList] // Only update if the initialProductList changes
  )

  return (
    <EstimateCreateQuickbooksObjModalStyled>
      {contextHolder}
      <Modal
        title={`Generate Quickbooks Invoice`}
        okText='Generate'
        open={open}
        onOk={handleOnOk}
        onCancel={onCancel}
        width={800}
        confirmLoading={loadingAccounts || isCreatingInvoice}
      >
        <Row style={{ margin: '4em 0' }}>
          <Col
            offset={6}
            span={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/logo_text_medium.png`}
              alt='Import to VoltPro'
              width={150}
            />
          </Col>
          <Col
            span={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ArrowRightOutlined size={24} />
          </Col>
          <Col
            span={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/assets/integrations/quickbooks/logo.png`}
                alt='Quickbooks bulk import'
                width={150}
              />
            </div>
          </Col>
        </Row>
        <Form
          layout='vertical'
          form={form}
          initialValues={{
            item_name_materials: 'Materials',
            item_name_labor: 'Labor',
            item_name_single: 'Materials and labor',
            item_account_materials: handleSetFirstOption(accountsList),
            item_account_labor: handleSetFirstOption(accountsList),
            item_account_single: handleSetFirstOption(accountsList),
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <CustomerDetailCard
                customer={estimate?.job?.customers?.[0]}
                extra={
                  <Space>
                    <Typography.Text type='secondary' ellipsis>
                      {estimate?.job?.customers?.[0]?.email}
                    </Typography.Text>
                  </Space>
                }
              />
            </Col>
            <Col span={12}>
              <Typography.Text>Creating an invoice will:</Typography.Text>
              <Space direction='vertical' style={{ margin: '0.5em 12px 0' }}>
                <Typography.Text>
                  <CheckCircleFilled /> Convert estimate data into a Quickbooks
                  invoice
                </Typography.Text>

                <Typography.Text>
                  <CheckCircleFilled /> Display only material and labor totals
                </Typography.Text>

                <Typography.Text>
                  <CheckCircleFilled /> Not send an email to your customer
                </Typography.Text>
              </Space>
            </Col>
          </Row>
          <Row gutter={24} style={{ margin: '2em -12px' }}>
            <Col span={10}>
              <Typography.Text strong>Label</Typography.Text>
            </Col>
            <Col span={8}>
              <Space>
                <Typography.Text strong>Quickbooks Account</Typography.Text>
                <Tooltip title="Items will be created and assigned to these accounts if they don't already exist.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Typography.Text strong>Total</Typography.Text>
            </Col>
          </Row>
          {!singleLineItem && (
            <>
              <Row gutter={24}>
                <Col span={10}>
                  <Form.Item
                    name='item_name_materials'
                    rules={[
                      {
                        message: 'Quickbooks materials label required',
                        required: true,
                      },
                    ]}
                  >
                    <Input allowClear placeholder={'Materials'} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='item_account_materials'
                    rules={[
                      {
                        message: 'Quickbooks account required',
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      loading={loadingAccounts}
                      placeholder='Search by item name'
                      options={handleFormatOptionsList(accountsList)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <Typography.Text>
                    {formatMoney(estimate.materials_total)}
                  </Typography.Text>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={10}>
                  <Form.Item
                    name='item_name_labor'
                    rules={[
                      {
                        message: 'Quickbooks labor label required',
                        required: true,
                      },
                    ]}
                  >
                    <Input allowClear placeholder={'Labor'} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='item_account_labor'
                    rules={[
                      {
                        message: 'Quickbooks account required',
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      loading={loadingAccounts}
                      placeholder='Search by item name'
                      options={handleFormatOptionsList(accountsList)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <Typography.Text>
                    {formatMoney(estimate.labor_total)}
                  </Typography.Text>
                </Col>
              </Row>
            </>
          )}

          {singleLineItem && (
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item
                  name='item_name_single'
                  rules={[
                    {
                      message: 'Quickbooks label required',
                      required: true,
                    },
                  ]}
                >
                  <Input allowClear placeholder={'Labor'} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='item_account_single'
                  rules={[
                    {
                      message: 'Quickbooks account required',
                      required: true,
                    },
                  ]}
                >
                  <Select
                    loading={loadingAccounts}
                    placeholder='Search by item name'
                    options={handleFormatOptionsList(accountsList)}
                  />
                </Form.Item>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography.Text>
                  {formatMoney(estimate.custom_subtotal || estimate.subtotal)}
                </Typography.Text>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </EstimateCreateQuickbooksObjModalStyled>
  )
}

EstimateCreateQuickbooksObjModal.displayName =
  'EstimateCreateQuickbooksObjModal'
