import { trucateNumber } from './index'

export const formatMaterialData = (materials: any) => {
  let formattedMaterials: any = []
  let sectionTitle: string

  materials.forEach((material: any, index: number) => {
    if (!material.id && !material.name && !material.priceId) {
      return
    }
    if (material?.type === 'section') {
      sectionTitle = material?.name
    } else {
      formattedMaterials.push({
        productId: material.id,
        priceId: material.priceId,
        name: material.name,
        quantity: material.quantity,
        markup: material.markup,
        type: material.type || 'good',
        price: trucateNumber(material.default_amount || material.price), // Sanitize long decimals before sending
        unit: material.unit,
        wastage_amount: material.wastage_amount,
        wastage_percentage: material.wastage_percentage,
        ...(material.fixed_price && {
          fixed_price: trucateNumber(material.fixed_price),
        }), // Sanitize long decimals before sending
        ...(sectionTitle && {
          metadata: {
            ...material.metadata,
            section: sectionTitle,
          },
        }),
      })
    }
  })

  return formattedMaterials
}

export const formatEstimateFormObj = (
  values: any,
  selectedCustomerObj?: any,
  appliedTaxesList?: any
) => {
  const {
    number,
    estimate_date,
    expiration_date,
    materials = [],
    work_scope,
    additional_content,
    custom_subtotal,

    // Pull out fields that should not be submitted
    _totals,
    customer,
    selected_tax_id,
    ...rest
  } = values

  return {
    jobId: selectedCustomerObj?.jobs?.[0]?.id || '',
    number,
    ...(estimate_date && {
      estimate_date: new Date(estimate_date).getTime(),
    }),
    ...(expiration_date && {
      expiration_date: new Date(expiration_date).getTime(),
    }),
    description: values.description,
    ...(materials && {
      products: formatMaterialData(materials),
    }),
    taxes: appliedTaxesList.map((tax: any) => {
      return {
        id: tax.id,
        display_name: tax.display_name,
        percentage: tax.percentage,
      }
    }),
    ...(work_scope && { work_scope }),
    ...(additional_content && { additional_content }),
    custom_subtotal: custom_subtotal ? trucateNumber(custom_subtotal) : null,
    ...rest,
  }
}
