import { ArrowRightOutlined } from '@ant-design/icons'
import { Form, Input, GetRef, InputNumber } from 'antd'
import { isEqual } from 'lodash'
import * as React from 'react'

import { EditableContext } from '../EditableContext'
import { MaterialDataType } from '../MaterialData.types'
import { QUANTITY_PRECISION } from '../../../../../config/default'

import { dollarInputFormatter, dollarInputParser } from '../../../../../utils'
import { SimpleSearchSelect } from '../../../../SimpleSearchSelect'
import { ServiceItemBadge } from '../../../../ServiceItemBadge'

interface CellProps {
  title: React.ReactNode
  editable: boolean
  inputType: 'text' | 'percent' | 'price' | 'quantity' | 'markup' | 'product'
  dataIndex: keyof MaterialDataType
  formattedOptionsList: any
  record: MaterialDataType
  handleSave: (record: MaterialDataType) => void
  handleFetchProducts: (value: string) => Promise<any>
  onCreateNewMaterialClick: (record: MaterialDataType) => void
  onInputError: (error: any, record: MaterialDataType) => void
}

export const FormTableCell = ({
  title,
  editable,
  inputType,
  children,
  dataIndex,
  formattedOptionsList,
  record,
  handleSave,
  handleFetchProducts,
  onCreateNewMaterialClick,
  onInputError,
  ...restProps
}: React.PropsWithChildren<CellProps>) => {
  const [editing, setEditing] = React.useState(false)
  const inputRef = React.useRef<any>(null)
  const form = React.useContext(EditableContext)!

  React.useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
      if (typeof inputRef.current?.select === 'function') {
        inputRef.current?.select()
      }
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const didValuesChange = (values: any, record: any) => {
    const formKeys: string[] = Object.keys(values)

    if (!formKeys.length) {
      return false
    }

    if (typeof values[formKeys[0]] === 'object') {
      return !isEqual(values[formKeys[0]], record[formKeys[0]])
    } else {
      return values[formKeys[0]] !== record[formKeys[0]]
    }
  }
  const save = async (e?: any, customRecordUpdates: any = {}) => {
    try {
      const values = await form.validateFields()

      toggleEdit()

      // To prevent unnecessary re-renders if no values change
      if (!didValuesChange(values, record)) {
        return
      }

      handleSave({ ...record, ...values, ...customRecordUpdates })
    } catch (errInfo) {
      // Pass error to parent to block submit functionality
      onInputError(errInfo, record)
    }
  }

  const handleSwitchToCustomMaterial = (searchValue?: string) => {
    if (!searchValue) return save()

    // We bypass the validation/save step here
    toggleEdit()

    handleSave({
      ...record,
      custom: true,
      type: 'good',
      name: { label: searchValue, value: '' },
    })
  }

  const handleOnSelectMateral = (materialData: any = {}, index: any) => {
    const { prices = [], fixed_price, type = 'good' } = materialData

    save(null, {
      ...(prices && prices?.[0]?.id && { priceId: prices?.[0]?.id }),
      price: prices?.[0]?.unit_amount || 0,
      fixed_price,
      type,
    })
  }

  let childNode = children

  // TODO refactor these into their own components
  if (editable) {
    if (editing) {
      switch (inputType) {
        case 'product':
          if (record.type === 'section') {
            childNode = (
              <Form.Item
                style={{ margin: 0, width: '100%' }}
                name={[dataIndex, 'label']}
                rules={[
                  {
                    required: true,
                    message: 'Item required',
                  },
                ]}
              >
                <Input
                  ref={inputRef}
                  placeholder={'Section Name'}
                  onPressEnter={save}
                  onBlur={save}
                />
              </Form.Item>
            )
          } else if (record.custom) {
            childNode = (
              <Form.Item
                style={{ margin: 0, width: '100%' }}
                name={[dataIndex, 'label']}
                rules={[
                  {
                    required: true,
                    message: 'Item required',
                  },
                ]}
              >
                <Input
                  ref={inputRef}
                  placeholder={record.type === 'service' ? 'Labor' : 'Material'}
                  onPressEnter={save}
                  onBlur={save}
                  suffix={record.type === 'service' && <ServiceItemBadge />}
                />
              </Form.Item>
            )
          } else {
            childNode = (
              <Form.Item
                style={{ margin: 0, width: '100%' }}
                name={dataIndex}
                rules={[
                  {
                    required: true,
                    message: 'Item required',
                  },
                ]}
              >
                <SimpleSearchSelect
                  ref={inputRef}
                  onBlurTriggered={(searchStringValue?: string, e?: any) => {
                    handleSwitchToCustomMaterial(searchStringValue)
                  }}
                  labelInValue={true}
                  onSelect={(value: any, option: any) => {
                    handleOnSelectMateral(option.data, dataIndex)
                  }}
                  placeholder='Search by item name'
                  fetchOptions={handleFetchProducts}
                  optionList={formattedOptionsList}
                  suffixIcon={
                    record.type === 'service' ? <ServiceItemBadge /> : ''
                  }
                  emptyText={
                    <>
                      <ArrowRightOutlined style={{ marginRight: '1em' }} />
                      Press tab to create a custom item
                    </>
                  }
                  style={{ margin: 0, width: '100%' }}
                />
              </Form.Item>
            )
          }
          break
        case 'price':
          childNode = (
            <Form.Item
              style={{ margin: 0, width: '100%' }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: 'Missing price',
                },
              ]}
            >
              {/* <Input defaultValue={0} /> */}
              <InputNumber
                ref={inputRef}
                placeholder='Price'
                decimalSeparator='.'
                formatter={dollarInputFormatter}
                parser={dollarInputParser}
                onPressEnter={save}
                onBlur={save}
                style={{ margin: 0, width: '100%' }}
              />
            </Form.Item>
          )
          break
        case 'quantity':
          childNode = (
            <Form.Item
              style={{ margin: 0, width: '100%' }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: 'Missing quantity',
                },
              ]}
              initialValue={1}
            >
              {/* <Input defaultValue={1} /> */}
              <InputNumber
                ref={inputRef}
                precision={QUANTITY_PRECISION}
                placeholder='Quantity'
                onPressEnter={save}
                onBlur={save}
                style={{ margin: 0, width: '100%' }}
              />
            </Form.Item>
          )
          break
        case 'markup':
          childNode = (
            <Form.Item
              style={{ margin: 0, width: '100%' }}
              name={dataIndex}
              rules={[
                {
                  required: false,
                  message: 'Missing markup',
                },
              ]}
            >
              <InputNumber
                ref={inputRef}
                min={0}
                placeholder='Markup'
                formatter={(value) =>
                  `${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                onPressEnter={save}
                onBlur={save}
                style={{ margin: 0, width: '100%' }}
              />
            </Form.Item>
          )
          break
        default:
          childNode = (
            <Form.Item
              style={{ margin: 0, width: '100%' }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
            >
              <Input
                ref={inputRef}
                onPressEnter={save}
                onBlur={save}
                style={{ margin: 0, width: '100%' }}
              />
            </Form.Item>
          )
          break
      }
    } else {
      childNode = (
        <div
          className={`editable-cell-value-wrap`}
          onClick={toggleEdit}
          onFocus={toggleEdit}
          tabIndex={0}
        >
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>
}

FormTableCell.displayName = 'FormTableCell'
