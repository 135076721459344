import React, { useState, useRef, useEffect, ReactNode } from 'react'
import { Button } from 'antd'
import { motion, AnimatePresence } from 'framer-motion'

export interface ExpandableContentProps {
  children: ReactNode
  maxHeight?: number
  showMoreText?: string
  showLessText?: string
}

export const ExpandableContent = ({
  children,
  maxHeight = 200,
  showMoreText = 'Show More',
  showLessText = 'Show Less',
}: ExpandableContentProps) => {
  const [expanded, setExpanded] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      setShowButton(contentRef.current.scrollHeight > maxHeight)
    }
  }, [children, maxHeight])

  return (
    <div>
      <motion.div
        ref={contentRef}
        initial={false}
        animate={{
          height: expanded ? 'auto' : maxHeight,
        }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        style={{ overflow: 'hidden', position: 'relative' }}
      >
        {children}
        {!expanded && showButton && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '50px',
              background:
                'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
            }}
          />
        )}
      </motion.div>
      {showButton && (
        <div style={{ textAlign: 'center' }}>
          <Button
            type='link'
            onClick={() => setExpanded(!expanded)}
            style={{ marginTop: '10px' }}
          >
            {expanded ? showLessText : showMoreText}
          </Button>
        </div>
      )}
    </div>
  )
}

ExpandableContent.displayName = 'ExpandableContent'
