import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Tag } from 'antd'
import * as React from 'react'

import { CustomerSelectItemRenderStyled } from './styles'

import { QuickbooksIcon } from '../../QuickbooksIcon'

export interface CustomerSelectItemRenderProps {
  title: string
  quickbooksId?: string | number
}

export const CustomerSelectItemRender = ({
  title = 'N/A',
  quickbooksId,
}: CustomerSelectItemRenderProps) => {
  return (
    <CustomerSelectItemRenderStyled>
      <Typography.Text
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {title}{' '}
        {quickbooksId && (
          <span style={{ marginRight: '10px' }}>
            <QuickbooksIcon />
          </span>
        )}
      </Typography.Text>
    </CustomerSelectItemRenderStyled>
  )
}

CustomerSelectItemRender.displayName = 'CustomerSelectItemRender'
