export const estimateFormSlice = (set: any) => ({
  estimateSubtotal: 0,
  estimateCustomSubtotal: 0,
  estimateTotal: 0,
  estimateCost: 0,
  estimateLaborCost: 0,
  estimateMaterialCost: 0,
  estimateProfit: 0,
  estimateLaborProfit: 0,
  estimateMaterialProfit: 0,
  estimateDiscountPercentage: 0,
  estimateFormErrors: {},
  setEstimateFormErrors: (estimateFormErrors: { ['string']: string }) =>
    set({ estimateFormErrors }),
  setEstimateSubtotal: (estimateSubtotal: string) => set({ estimateSubtotal }),
  setEstimateTotal: (estimateTotal: number) => set({ estimateTotal }),
  setEstimateLaborCost: (estimateLaborCost: number) =>
    set({ estimateLaborCost }),
  setEstimateMaterialCost: (estimateMaterialCost: number) =>
    set({ estimateMaterialCost }),
  setEstimateProfit: (estimateProfit: number) => set({ estimateProfit }),
  setTotals: (
    estimateSubtotal: number,
    estimateTotal: number,
    estimateCost: number,
    estimateLaborCost: number,
    estimateMaterialCost: number,
    estimateCustomSubtotal: number,
    estimateDiscountPercentage: number
  ) =>
    set({
      estimateSubtotal,
      estimateTotal,
      estimateCost,
      estimateLaborCost,
      estimateMaterialCost,
      estimateCustomSubtotal,
      estimateDiscountPercentage,
    }),
  setProfits: (
    estimateProfit: number,
    estimateLaborProfit: number,
    estimateMaterialProfit: number
  ) =>
    set({
      estimateProfit,
      estimateLaborProfit,
      estimateMaterialProfit,
    }),
})
