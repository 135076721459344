import {
  User,
  Tool,
  Home,
  DollarSign,
  LogOut,
  Zap,
  MessageCircle,
} from 'react-feather'
import { useClerk } from '@clerk/clerk-react'
import type { MenuProps } from 'antd'
import { Layout, Divider, Space, Typography, Menu } from 'antd'
import * as React from 'react'
import { NavLink, useLocation, Outlet, useNavigate } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'

const { Sider, Content } = Layout

import { useAuth0 } from '@auth0/auth0-react'
import { PreferenceViewStyled } from './styles'

export interface PreferenceViewProps {}

export const PreferenceView = ({ ...props }: PreferenceViewProps) => {
  const [current, setCurrent] = React.useState<string[]>([])
  const { signOut } = useClerk()
  const location = useLocation()
  const navigate = useNavigate()

  const isExperimentalOrg = useAppStore((state: any) => state.isExperimental)

  React.useEffect(() => {
    if (location.pathname) {
      const snippets = location.pathname.split('/').filter((i) => i)
      if (snippets.length > 0) {
        setCurrent([snippets.join('/')])
      }
    }
  }, [location])

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key !== 'logout') {
      setCurrent([e.key])
      navigate(`/${e.key}`, { replace: true })
    }
  }

  let items: MenuProps['items'] = [
    {
      label: 'Profile',
      key: 'settings/profile',
      icon: <User size={16} />,
    },
    {
      label: 'Organization',
      key: 'settings/organization',
      icon: <Home size={16} />,
    },
    {
      label: 'Estimates',
      key: 'settings/estimates',
      icon: <Tool size={16} />,
    },
    {
      label: 'Membership',
      key: 'settings/membership',
      icon: <DollarSign size={16} />,
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogOut size={16} />,
      style: {
        marginTop: '1em',
        borderTop: '1px solid #eee',
        opacity: 0.75,
      },
      onClick: () => {
        signOut(() => {
          navigate('/')
        })
      },
    },
  ]

  if (isExperimentalOrg) {
    items.splice(-2, 0, {
      label: 'Integrations',
      key: 'settings/integrations',
      icon: <Zap size={16} />,
    })
  }

  return (
    <PreferenceViewStyled>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          theme='light'
          width={250}
          style={{ padding: '3.5em 2em', boxShadow: '0 0 1px 1px #e6e6e6' }}
        >
          <Typography.Title level={4}>Settings</Typography.Title>
          <Menu
            onClick={onClick}
            selectedKeys={current}
            items={items}
            style={{ marginTop: '62px' }}
          />

          <div className='support-contact-btn'>
            <Space direction='vertical'>
              <a href='mailto:contact@getvoltpro.com'>
                <MessageCircle size={14} />
                <Typography.Text>Contact support</Typography.Text>
              </a>
            </Space>
          </div>
        </Sider>

        <Content style={{ padding: '3.5em 2em' }}>
          <Outlet />
        </Content>
      </Layout>
    </PreferenceViewStyled>
  )
}

PreferenceView.displayName = 'PreferenceView'
