import { AlertOctagon } from 'react-feather'
import React, { useState, useEffect, useCallback } from 'react'
import { Modal } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { MobileWarningStyled } from './styles'

import { theme } from '../../styles/themes/default'

interface MobileWarningProps {
  mobileBreakpoint?: number
  excludedRoutes?: string[]
}

export const MobileWarning: React.FC<MobileWarningProps> = React.memo(
  ({ mobileBreakpoint = 768, excludedRoutes = ['/sign-in', '/sign-up'] }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const isMobileOrTablet = useMediaQuery({ maxWidth: mobileBreakpoint })
    const location = useLocation()

    useEffect(() => {
      const shouldShowWarning =
        isMobileOrTablet && !excludedRoutes.includes(location.pathname)
      setIsModalVisible(shouldShowWarning)
    }, [isMobileOrTablet])

    const modalProps = useCallback(
      () => ({
        open: isModalVisible,
        closable: false,
        footer: null,
        maskClosable: false,
        keyboard: false,
        style: {
          top: 0,
          paddingBottom: 0,
        },
        styles: {
          body: {
            height: '100vh',
            // Adjust content height to account for header and footer
            maxHeight: 'calc(100vh - 110px)', // 110px accounts for modal header + footer + padding
            overflow: 'auto',
          },
        },
      }),
      [isModalVisible]
    )

    if (!isMobileOrTablet) return null

    return (
      <MobileWarningStyled>
        <Modal {...modalProps()}>
          <div style={{ textAlign: 'center', margin: '2em 0' }}>
            <AlertOctagon size={36} style={{ color: theme.colors.accent }} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <h2>Unsupported Device</h2>

            <p>
              Mobile and tablet devices are not yet supported on VoltPro. Create
              estimates from a laptop or desktop for the best experience!
            </p>
          </div>
        </Modal>
      </MobileWarningStyled>
    )
  }
)

MobileWarning.displayName = 'MobileWarning'
