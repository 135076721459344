import { Award } from 'react-feather'
import { UserOutlined, CheckOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  message,
  Space,
  Spin,
  Flex,
  Typography,
  Upload,
} from 'antd'
import debounce from 'lodash/debounce'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useAuth } from '@clerk/clerk-react'

import { formatDate, isBasicPlan } from '../../utils'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { PageTitle } from '../../components/PageTitle'
import { StripePricingTable } from '../../components/StripePricingTable'
import { useAppStore } from '../../stores/appStore'
import { theme } from '../../styles/themes/default'
import {
  useGetQuickbooksAuthUrlLazyQuery,
  useDisconnectQuickbooksMutation,
} from '../../graphql/_generated-hooks'
import { PreferenceIntegrationViewStyled } from './styles'
import { QuickbooksIntegrationButton } from '../../components/QuickbooksIntegrationButton'

const QuickbooksLogo = `${process.env.PUBLIC_URL}/assets/integrations/quickbooks/logo.png`

export interface PreferenceIntegrationViewProps {
  me?: any
}

const foundIntegrationConfig = (integrationConfigs: any, provider: string) => {
  return integrationConfigs.find(
    (config: any) => config.enabled && config.provider === provider
  )
}

export const PreferenceIntegrationView = ({
  me,
}: PreferenceIntegrationViewProps) => {
  let navigate = useNavigate()
  const { sessionId, isLoaded, getToken } = useAuth()

  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const [redirectUrl, setRedirectUrl] = React.useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  const quickbooksIntegrationConfig = useAppStore(
    (state: any) => state.quickbooksConfig
  )
  const setQuickbooksIntegrationConfig = useAppStore(
    (state: any) => state.setQuickbooksConfig
  )

  React.useEffect(() => {
    if (me?.organization?.integrationConfigs) {
      const quickbooksConfig = foundIntegrationConfig(
        me.organization.integrationConfigs,
        'quickbooks'
      )
      setQuickbooksIntegrationConfig(quickbooksConfig)
    }
  }, [me])

  const [
    getQuickbooksAuthUrl,
    { data: quickbooksAuthUrl, loading: loadingQuickbooksAuthUrl },
  ] = useGetQuickbooksAuthUrlLazyQuery()

  const [disconnectQuickbooks, { loading: loadingQuickbooksDisconnect }] =
    useDisconnectQuickbooksMutation()

  const handleQuickbooksAuth = async () => {
    // getToken({ template: 'volt-jwt-template' }).then(async (token) => {
    //   await fetch('http://localhost:3000/quickbooks/auth', {
    //     method: 'GET', // Assuming it's a GET request, change if needed
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       'Content-Type': 'application/json', // Include this if you're sending JSON data
    //     },
    //   })
    //     .then((response) => {
    //       if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`)
    //       }
    //       return response.json() // Or response.text() if the response is not JSON
    //     })
    //     .catch((error) => {
    //       console.error('There was a problem with the fetch operation:', error)
    //     })
    // })

    getQuickbooksAuthUrl({
      fetchPolicy: 'network-only',
      onCompleted({ connectQuickbooks }) {
        if (typeof window !== 'undefined' && connectQuickbooks?.url) {
          window.location.href = connectQuickbooks?.url
        } else {
          messageApi.error('Error connecting to QuickBooks: No URL returned.')
        }
      },
      onError(error) {
        messageApi.error('An unexpected error occured. Please try again.')
      },
    })
  }

  const handleQuickbooksDisconnect = () => {
    disconnectQuickbooks({
      variables: {},
      onCompleted({ disconnectQuickbooks }) {
        if (disconnectQuickbooks?.success) {
          setQuickbooksIntegrationConfig(null)
        }
      },
      onError(error) {
        messageApi.error('An unexpected error occured. Please try again.')
      },
    })
  }

  const handleNavigateToUpgrade = () => {
    navigate('/settings/membership')
  }

  return (
    <PreferenceIntegrationViewStyled>
      {contextHolder}
      <AnimatedComponent>
        <Content style={{ padding: '2em' }}>
          <PageTitle
            title='Integrations'
            subtitle='Seamlessly connect VoltPro to other platforms.'
          />

          <Spin spinning={loadingQuickbooksAuthUrl}>
            <Card style={{ marginTop: '2em', maxWidth: '800px' }}>
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Flex align='center' justify='space-between'>
                    <Space
                      direction='vertical'
                      size={15}
                      style={{
                        width: '350px',
                        padding: '20px',
                      }}
                    >
                      <img
                        src={QuickbooksLogo}
                        alt='Quickbooks'
                        style={{ width: '200px' }}
                      />
                      <Typography.Text type='secondary'>
                        Connect your QuickBooks account to VoltPro to sync your
                        customers, bills, materials, and estimates.
                      </Typography.Text>
                    </Space>
                    {isBasicPlan(me) ? (
                      <>
                        {quickbooksIntegrationConfig ? (
                          <Space direction='vertical' align='end'>
                            <Typography.Text
                              type='success'
                              style={{ fontSize: '18px', textAlign: 'right' }}
                            >
                              <CheckOutlined style={{ marginRight: '0.5em' }} />
                              Connected
                            </Typography.Text>
                            <Typography.Text
                              type='secondary'
                              style={{ marginLeft: '1em' }}
                            >
                              Last updated:{' '}
                              {formatDate(
                                quickbooksIntegrationConfig?.lastSync
                              )}
                            </Typography.Text>
                            <Button
                              type='link'
                              danger
                              style={{ padding: 0 }}
                              loading={loadingQuickbooksDisconnect}
                              onClick={handleQuickbooksDisconnect}
                            >
                              Disconnect
                            </Button>
                          </Space>
                        ) : (
                          <QuickbooksIntegrationButton
                            onClick={handleQuickbooksAuth}
                          />
                        )}
                      </>
                    ) : (
                      <Typography.Text>
                        <Button
                          type='primary'
                          onClick={handleNavigateToUpgrade}
                        >
                          Upgrade required
                        </Button>
                      </Typography.Text>
                    )}
                  </Flex>
                </Col>
              </Row>
            </Card>
          </Spin>
        </Content>
      </AnimatedComponent>
    </PreferenceIntegrationViewStyled>
  )
}

PreferenceIntegrationView.displayName = 'PreferenceIntegrationView'
