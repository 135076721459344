import { rem, tint } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const InvoicesTableStyled = styled.div`
  font-size: ${rem(16)};

  .selectable {
    cursor: pointer;
  }

  .selected {
    background-color: ${(props) => tint(0.9, props.theme.colors.primary)};

    &:hover td {
      background-color: ${(props) =>
        tint(0.9, props.theme.colors.primary)} !important;
    }
  }
`
