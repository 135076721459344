import {
  Modal,
  Row,
  Col,
  Typography,
  Form,
  Space,
  Divider,
  Input,
  message,
} from 'antd'
import * as React from 'react'

import { formatCentsToDollars, formatCents } from '../../utils'
import { CustomSubtotalModalStyled } from './styles'

export interface CustomSubtotalModalProps {
  open?: boolean
  currentSubtotal?: any
  onClose?: () => void
  onSubtotalSave?: (amount: number) => void
}

export const CustomSubtotalModal = ({
  open,
  currentSubtotal = {},
  onClose,
  onSubtotalSave = () => {},
}: CustomSubtotalModalProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()

  const handleOnOk = async () => {
    const values = await form.validateFields()
    // Submit
    const amount = formatCents(values.custom_subtotal)
    onSubtotalSave(amount)
  }

  return (
    <CustomSubtotalModalStyled>
      {contextHolder}
      <Modal
        title={`Custom Subtotal`}
        okText='Save'
        open={open}
        onOk={handleOnOk}
        onCancel={onClose}
      >
        <Form
          form={form}
          initialValues={{
            custom_subtotal: formatCentsToDollars(currentSubtotal) || 0,
          }}
          name='custom_subtotal_form'
          layout='vertical'
        >
          <Row style={{ marginTop: '2em' }}>
            <Col span={24}>
              <Form.Item
                name={'custom_subtotal'}
                label='Subtotal'
                rules={[
                  {
                    required: true,
                    message: 'Subtotal required',
                  },
                ]}
              >
                <Input placeholder={'Subtotal'} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </CustomSubtotalModalStyled>
  )
}

CustomSubtotalModal.displayName = 'CustomSubtotalModal'
