import { Row, Col, Divider, Skeleton, Typography, Flex } from 'antd'
import * as React from 'react'

import { theme } from '../../../styles/themes/default'
import { EstimatePDFPreviewStyled } from './styles'
import { capitalizeString } from '../../../utils'
import { EstimatePdfBreakdownOptions } from '../../../graphql/_generated-hooks'

export interface EstimatePDFPreviewProps {
  logo?: string
  estimateNumber?: string
  breakdownOption?: string
  renderSections?: Boolean
  renderSignature?: Boolean
}

export const EstimatePDFPreview = ({
  logo,
  estimateNumber,
  breakdownOption,
  renderSections,
  renderSignature,
}: EstimatePDFPreviewProps) => {
  return (
    <EstimatePDFPreviewStyled>
      <div className='pdf-preview-container'>
        <Row gutter={[16, 24]} justify='space-between'>
          <Col span={16}>
            <Skeleton
              title={false}
              paragraph={{
                rows: 2,
                width: ['60%', '60%'],
              }}
            />
          </Col>
          <Col span={8}>
            {logo && (
              <img
                style={{ maxWidth: 100, maxHeight: 100 }}
                src={logo}
                alt='logo'
              />
            )}
          </Col>
        </Row>
        <Row gutter={[16, 24]} justify='space-between'>
          <Col span={6}>
            <Skeleton
              title={false}
              paragraph={{
                rows: 1,
                width: ['100%'],
              }}
            />
          </Col>
          <Col span={6}>
            <Skeleton
              title={false}
              paragraph={{
                rows: 1,
                width: ['100%'],
              }}
            />
          </Col>
          <Col span={6}>
            <Skeleton
              title={false}
              paragraph={{
                rows: 1,
                width: ['100%'],
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            {/* <Typography.Text>Scope of work</Typography.Text> */}
            <Skeleton
              title={false}
              paragraph={{
                rows: 1,
                width: ['100%'],
              }}
            />
          </Col>
        </Row>

        <Row gutter={[16, 24]} justify='space-between'>
          <Col span={24}>
            {!breakdownOption ||
              (breakdownOption ===
                EstimatePdfBreakdownOptions.ShowSubtotals && (
                <Row className='amounts-row'>
                  <Col span={12}>Subtotal</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    $$$
                  </Col>
                </Row>
              ))}
            {breakdownOption === EstimatePdfBreakdownOptions.ShowSections && (
              <>
                <Row className='amounts-row'>
                  <Col span={12}>Section 1</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    $$$
                  </Col>
                </Row>
                <Row className='amounts-row'>
                  <Col span={12}>Section 2</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    $$$
                  </Col>
                </Row>
                <Row className='amounts-row'>
                  <Col span={12}>Misc.</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    $$$
                  </Col>
                </Row>
              </>
            )}
            {breakdownOption === EstimatePdfBreakdownOptions.ShowItems && (
              <>
                <Row className='amounts-row'>
                  <Col span={12}>Item 1</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    qty xx
                  </Col>
                </Row>
                <Row className='amounts-row'>
                  <Col span={12}>Item 2</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    qty xx
                  </Col>
                </Row>
                <Row className='amounts-row'>
                  <Col span={12}>Item 3</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    qty xx
                  </Col>
                </Row>
                <Row className='amounts-row'>
                  <Col span={12}>Subtotal</Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    $$$
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>

        <Row gutter={[16, 24]}>
          <Col span={12} offset={12}>
            <Skeleton
              title={false}
              paragraph={{
                rows: 3,
                width: ['100%'],
              }}
            />
          </Col>
        </Row>

        {/* <Row gutter={[16, 24]}>
          <Col span={24}>
            <Typography.Text>Additional content</Typography.Text>
            <Skeleton
              paragraph={{
                rows: 2,
              }}
            />
          </Col>
        </Row> */}

        {renderSignature && (
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <div className='signature-block' />
              <Typography.Text>Signature</Typography.Text>
              <Typography.Text style={{ float: 'right' }}>Date</Typography.Text>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 24]}>
          <Col span={12}>
            <Skeleton paragraph={false} />
          </Col>
          <Col span={12}>
            <Skeleton paragraph={false} />
          </Col>
        </Row>
      </div>
    </EstimatePDFPreviewStyled>
  )
}

EstimatePDFPreview.displayName = 'EstimatePDFPreview'
