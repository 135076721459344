import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const ExpandableContentStyled = styled.div`
  color: ${(props) => props.theme.colors.primary};
  line-height: 24px;

  p {
    min-height: ${rem(12)};
    margin: 5px 0;
  }
`
