import { ArrowRightOutlined } from '@ant-design/icons'
import {
  Modal,
  Row,
  Col,
  Typography,
  Form,
  Space,
  Divider,
  Alert,
  message,
} from 'antd'
import * as React from 'react'
import {
  useParams,
  useSearchParams,
  redirect,
  useNavigate,
} from 'react-router-dom'

import { useBulkImportCustomersFromQuickbooksMutation } from '../../graphql/_generated-hooks'
import { setApiFormErrors, setGraphQLFormErrors } from '../../utils'

import { QuickbooksBulkSyncModalStyled } from './styles'
export interface QuickbooksBulkSyncModalProps {
  open?: boolean
  requiresUpgrade?: boolean
  syncType?: 'customers'
  onCancel?: () => void
  onBulkSyncSuccess?: (id: string) => void
}

export const QuickbooksBulkSyncModal = ({
  open,
  requiresUpgrade,
  syncType = 'customers',
  onCancel,
  onBulkSyncSuccess = () => {},
}: QuickbooksBulkSyncModalProps) => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()

  // const [duplicateEstimate, { data, loading: isDuplicatingEstimate, error }] =
  //   useDuplicateEstimateMutation()
  const [bulkImportCustomers, { data, loading: isImportingCustomers, error }] =
    useBulkImportCustomersFromQuickbooksMutation()

  const handleOnOk = async () => {
    const values = await form.validateFields()
    // Submit
    try {
      if (syncType === 'customers') {
        bulkImportCustomers({
          onCompleted({ bulkImportCustomersFromQuickbooks }: any = {}) {
            onBulkSyncSuccess(bulkImportCustomersFromQuickbooks?.stats)
          },
          onError(error, clientOptions) {
            if (error?.graphQLErrors?.[0]?.message) {
              messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
            } else {
              messageApi.error('An error occurred. Please try again.')
            }
          },
        })
      }
      // duplicateEstimate({
      //   variables: {
      //     id: estimateObj?.id,
      //     input: {
      //       number: values.number,
      //     },
      //   },
      //   onCompleted({ duplicateEstimate }: any = {}) {
      //     setIsNavigating(true)
      //     messageApi.success('Success: Estimate duplicated successfully!')
      //     if (duplicateEstimate?.data?.id) {
      //       onDuplicateSuccess(duplicateEstimate?.data?.id)
      //       setTimeout(() => {
      //         navigate(`/estimates/${duplicateEstimate.data.id}`, {
      //           replace: true,
      //         })
      //         setIsNavigating(false)
      //       }, 1500)
      //     }
      //   },
      //   onError(error, clientOptions) {
      //     if (error?.graphQLErrors?.[0]?.message) {
      //       messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      //     } else {
      //       messageApi.error('An error occurred. Please try again.')
      //     }
      //     setApiFormErrors(error.graphQLErrors, form)
      //   },
      // })
    } catch (errInfo: any) {
      // GraphQL validation errors will catch here
      if (errInfo && errInfo?.errorFields?.length) {
        messageApi.error(
          `An error occurred: ${errInfo.errorFields[0]?.errors[0]}`
        )
      }
    }
  }

  const handleUpgradeNavigate = () => {
    navigate('/settings/membership')
  }

  return (
    <QuickbooksBulkSyncModalStyled>
      {contextHolder}
      <Modal
        title={`Bulk import ${syncType}`}
        okText='Import'
        open={open}
        onOk={handleOnOk}
        onCancel={onCancel}
        confirmLoading={isImportingCustomers}
        okButtonProps={{
          disabled: requiresUpgrade,
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>

            {requiresUpgrade && (
              <div style={{ marginTop: 10 }}>
                <Typography.Text type='secondary' style={{ fontSize: 12 }}>
                  <a onClick={handleUpgradeNavigate}>Upgrade</a> to unlock this
                  feature.
                </Typography.Text>
              </div>
            )}
          </>
        )}
      >
        <Row style={{ margin: '40px 0' }}>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/integrations/quickbooks/logo.png`}
              alt='Quickbooks bulk import'
              width={150}
            />
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '40px 0',
              }}
            >
             
            </div> */}
          </Col>
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ArrowRightOutlined size={24} />
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/logo_text_medium.png`}
                alt='Import to VoltPro'
                width={150}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '20px 0' }}>
          <Col span={24}>
            <Typography.Text>
              Bulk import your customers from Quickbooks to VoltPro. All future
              updates to customers will be synced.
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Text>
              Importing customers will not merge or overwrite any existing
              customers in VoltPro.
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
    </QuickbooksBulkSyncModalStyled>
  )
}

QuickbooksBulkSyncModal.displayName = 'QuickbooksBulkSyncModal'
