import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Typography, Row, Space, Flex } from 'antd'
import React, { useState, memo } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { PDFViewerStyled } from './styles'
export interface PDFViewerProps {
  fileUrl: string
  pageWidth?: number
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFViewerComponent = ({
  fileUrl,
  pageWidth,
  ...props
}: PDFViewerProps) => {
  const [numPages, setNumPages] = useState(null)
  const options = React.useMemo(
    () => ({
      httpHeaders: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,OPTIONS',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      },
    }),
    []
  )

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
  }

  return (
    <PDFViewerStyled {...props}>
      <Document
        options={options}
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            width={pageWidth}
          />
        ))}
      </Document>
    </PDFViewerStyled>
  )
}

// Stops rerendering of PDFViewer
export const PDFViewer = memo(PDFViewerComponent)

PDFViewer.displayName = 'PDFViewer'
