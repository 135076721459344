import { Tooltip } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { CondintionalTooltipStyled } from './styles'

export interface CondintionalTooltipProps {
  showTooltip?: boolean
  title?: string
  children?: React.ReactNode
}

export const CondintionalTooltip = ({
  showTooltip,
  title,
  children,
}: CondintionalTooltipProps) => {
  return (
    <CondintionalTooltipStyled>
      {showTooltip && <Tooltip title={title}>{children}</Tooltip>}

      {!showTooltip && <>{children}</>}
    </CondintionalTooltipStyled>
  )
}

CondintionalTooltip.displayName = 'CondintionalTooltip'
